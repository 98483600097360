import { useEffect, useState } from 'react';

export default function useOrientation() {
  const [orientation, setOrientation] = useState(window.innerWidth < window.innerHeight ? 'PORTRAIT' : 'LANDSCAPE');

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth < window.innerHeight) {
        setOrientation('PORTRAIT');
      } else {
        setOrientation('LANDSCAPE');
      }
    });
  }, []);

  return orientation;
}
