import React, { SVGProps } from 'react';

export default function IconRotatePhone(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 59" {...props}>
      <g transform="translate(-43 -87)">
        <path d="M24.5,0,49,26H0Z" transform="translate(92 146) rotate(180)" fill="#fff" />
        <rect width="21" height="36" transform="translate(57 87)" fill="#fff" />
      </g>
    </svg>
  );
}
