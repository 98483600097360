import React from 'react';

export default function AvatarIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="123.657" height="125.801" viewBox="0 0 123.657 125.801">
      <g transform="translate(-225.672 -21.672)">
        <g transform="translate(-297 202)">
          <path
            d="M42,0A42,42,0,0,1,84,42c0,23.2-42,64.471-42,64.471S0,65.2,0,42A42,42,0,0,1,42,0Z"
            transform="translate(542 -161)"
            fill="#26242d"
          />
          <circle cx="14" cy="14" r="14" transform="translate(570 -133)" fill="#26242d" />
        </g>
        <g transform="translate(-2032 -810)">
          <line x2="118" y2="118" transform="translate(2260.5 834.5)" fill="none" stroke="#ffca40" strokeWidth="8" />
          <line x1="118" y2="118" transform="translate(2260.5 834.5)" fill="none" stroke="#ffca40" strokeWidth="8" />
        </g>
      </g>
    </svg>
  );
}
