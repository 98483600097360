import React, { useEffect } from 'react';
import { Typography, makeStyles, Button, Theme, styled } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Steps } from '../../../App';
import ImageCarInFrame from './ImageCarInFrame';
import Icon360VideoDiagram from './Icon360VideoDiagram';
import IconObstructedVideoDiagram from './IconObstructedVideoDiagram';
import trackEvent from '../../../utils/trackEvent/trackEvent';

const useStyles = makeStyles((theme: Theme) => ({
  textFieldContainer: {
    width: '100%',
  },
  buttonLink: {
    textDecoration: 'none',
  },
  generalButton: {
    display: 'block',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  buttonText: {
    marginTop: '1rem',
  },
  threesixtyButton: {
    background: '#EBEDF2',
    color: '#8F09F9',
    '&:hover': {
      backgroundColor: '#E2E6ef',
    },
  },
  threeSixtyContainer: {
    paddingTop: '14px',
  },
  obstructedButton: {
    border: 'solid 2px #EBEDF2',
    background: '#ffffff',
    color: '#FFCA40',
    '&:hover': {
      backgroundColor: '#EBEDF2',
    },
  },
  largeIconContainer: {
    boxSizing: 'border-box',
    height: '110px',
  },
  headerTitle: {
    background: 'none',
  },
  obstructedTitle: {
    marginTop: '2.6rem',
  },
  getStarted: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  getStartedInner: {
    flex: '1',
    borderRadius: '10px',
    padding: '1.5rem',
    maxWidth: '380px',
  },
  explanation: {
    marginTop: '10px',
    marginBottom: '20px',
    lineHeight: '1.3rem',
  },
  instructionContent: {
    boxSizing: 'border-box',
    maxWidth: '480px',
    margin: '0 auto',
    padding: '20px',
  },
  declineButtonContainer: {
    paddingTop: '15px',
  },
  buttonContainer: {
    textAlign: 'center',
    paddingTop: '5px',
  },
  notTheDriverTitle: {
    marginTop: '2.6rem',
  },
  subText: {
    marginTop: '10px',
    marginBottom: '20px',
    lineHeight: '1.3rem',
  },
}));

const Header = styled('header')(() => ({
  background: '#150F1A',
  textAlign: 'center',
  padding: '1.5rem 1rem',
  borderRadius: '0 0 4rem 4rem',
  '& h1': {
    marginTop: '1rem',
    color: '#ffffff',
  },
}));

interface VideoInstructionScreenProps {
  step: number;
  setStep: (step: Steps) => void;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function VideoInstructionScreen({ step, setStep }: VideoInstructionScreenProps) {
  const classes = useStyles();
  let query = useQuery();
  let messageDelim = '&';

  if (/Android/.test(navigator.userAgent)) {
    messageDelim = '?';
  }

  // Incase a previous screen had scrolled
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sendToAnother = () => {
    trackEvent(query.get('id') as string, {
      key: 'clicked-send-to-another',
      value: JSON.stringify({
        app_version: window.appVersion,
      }),
    });
  };

  useEffect(() => {
    let body = document.getElementsByTagName('body');
    if (body) {
      body[0].style.backgroundColor = '#ffffff';
    }
  }, []);

  const toRecord360Video = () => {
    setStep(Steps.videoCameraStep);
  };

  const toRecordObstructedVideo = () => {
    setStep(Steps.videoCameraStep);
  };

  return (
    <>
      <Header>
        <ImageCarInFrame />
        <Typography variant="h1">360 Video</Typography>
      </Header>
      <div className={classes.instructionContent}>
        <Typography variant="body1" className={classes.explanation}>
          Now it's time to record a video capturing all sides of the vehicle. This helps us protect you (and us) from
          claims.
        </Typography>
        <div className={classes.buttonContainer}>
          <Button
            onClick={toRecord360Video}
            variant="contained"
            type="submit"
            color="primary"
            className={classes.generalButton + ' ' + classes.threesixtyButton}
          >
            <div className={classes.largeIconContainer + ' ' + classes.threeSixtyContainer}>
              <Icon360VideoDiagram />
            </div>
            <div className={classes.buttonText}>Record 360 video</div>
          </Button>
        </div>
        <Typography variant="h1" className={classes.obstructedTitle}>
          Obstructed or unsafe?
        </Typography>

        <Typography variant="body1" className={classes.explanation}>
          If the an object or safety issue prevents you from recording the entire vehicle, record as much as you can.
        </Typography>
        <div className={classes.buttonContainer}>
          <Button
            onClick={toRecordObstructedVideo}
            variant="contained"
            type="submit"
            color="primary"
            className={classes.generalButton + ' ' + classes.obstructedButton}
          >
            <div className={classes.largeIconContainer}>
              <IconObstructedVideoDiagram />
            </div>
            <div className={classes.buttonText}>Record partial video</div>
          </Button>
        </div>
        <Typography variant="h1" className={classes.notTheDriverTitle}>
          Not the driver?
        </Typography>
        <Typography variant="body1" className={classes.subText}>
          Send this to the person doing the tow so that they can take the video.
        </Typography>
        <div className={classes.buttonContainer}>
          <a
            className={classes.buttonLink}
            href={
              'sms:' +
              messageDelim +
              'body=Use%20the%20HONK%20Pre-tow%20Photo%20And%20Video%20app%20to%20record%20the%20vehicle%20before%20it%27s%20loaded:%20%0a' +
              window.location.href
            }
          >
            <Button onClick={sendToAnother} variant="contained" color="primary" className={classes.generalButton}>
              Send to driver
            </Button>
          </a>
        </div>
        <br />
        <br />
        <br />
      </div>
    </>
  );
}
