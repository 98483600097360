import React, { SVGProps } from 'react';

export default function IconRotatePhone(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="205" height="79" viewBox="0 0 205 79" {...props}>
      <defs>
        <clipPath id="a">
          <rect width="141.997" height="58.427" fill="none" />
        </clipPath>
      </defs>
      <g transform="translate(-85 -16)">
        <g transform="translate(116.502 27.287)" clipPath="url(#a)">
          <path
            d="M33.428,169.1c-4.111,0-7.428,5.561-7.428,12.412s3.317,12.412,7.428,12.412h7.189V169.1Z"
            transform="translate(-20.836 -135.517)"
            fill="#26242d"
          />
          <path
            d="M77.055,181.532c0,6.852-3.317,12.412-7.428,12.412S62.2,188.384,62.2,181.532,65.536,169.1,69.628,169.1s7.428,5.561,7.428,12.412"
            transform="translate(-49.847 -135.517)"
            fill="#26242d"
          />
          <path
            d="M83.324,194.114c0,5.144-2.4,9.314-5.362,9.314s-5.362-4.171-5.362-9.314S75,184.8,77.962,184.8s5.362,4.171,5.362,9.314"
            transform="translate(-58.182 -148.099)"
            fill="#26242d"
          />
          <path
            d="M618.215,164.135s6.2.238,8,0a7.328,7.328,0,0,0,2.224-1.192q.1-.576.179-1.172c1.251-9.533-1.946-17.794-7.09-18.43s-10.347,6.554-11.6,16.106a31.5,31.5,0,0,0-.258,4.687Z"
            transform="translate(-488.591 -114.844)"
            fill="#26242d"
          />
          <path
            d="M588.427,169.1c-4.111,0-7.427,5.561-7.427,12.412s3.317,12.412,7.427,12.412h7.189V169.1Z"
            transform="translate(-465.615 -135.517)"
            fill="#26242d"
          />
          <path
            d="M632.055,181.612c0,6.852-3.317,12.412-7.428,12.412s-7.427-5.561-7.427-12.412,3.317-12.412,7.427-12.412,7.428,5.561,7.428,12.412"
            transform="translate(-494.626 -135.597)"
            fill="#26242d"
          />
          <path
            d="M638.324,194.114c0,5.144-2.4,9.314-5.362,9.314s-5.362-4.171-5.362-9.314,2.4-9.314,5.362-9.314,5.362,4.171,5.362,9.314"
            transform="translate(-502.961 -148.099)"
            fill="#fff"
          />
          <path
            d="M322.528,169.1c-4.111,0-7.428,5.561-7.428,12.412s3.317,12.412,7.428,12.412h7.189V169.1Z"
            transform="translate(-252.522 -135.517)"
            fill="#26242d"
          />
          <path
            d="M366.155,181.612c0,6.852-3.317,12.412-7.428,12.412s-7.428-5.561-7.428-12.412,3.317-12.412,7.428-12.412,7.428,5.561,7.428,12.412"
            transform="translate(-281.533 -135.597)"
            fill="#26242d"
          />
          <path
            d="M372.424,194.114c0,5.144-2.4,9.314-5.362,9.314s-5.362-4.171-5.362-9.314,2.4-9.314,5.362-9.314,5.362,4.171,5.362,9.314"
            transform="translate(-289.867 -148.099)"
            fill="#26242d"
          />
          <path
            d="M328.905,167.678a25.877,25.877,0,0,0,.814-3.873c1.211-9.354-2.761-17.576-8.877-18.33s-11.817,6.236-13.286,15.55a40.059,40.059,0,0,0-.556,6.653Z"
            transform="translate(-246.031 -116.539)"
            fill="#26242d"
          />
          <path
            d="M299.427,169.1c-4.111,0-7.428,5.561-7.428,12.412s3.317,12.412,7.428,12.412h7.189V169.1Z"
            transform="translate(-234.01 -135.517)"
            fill="#26242d"
          />
          <path
            d="M342.955,181.612c0,6.852-3.317,12.412-7.428,12.412s-7.428-5.561-7.428-12.412,3.317-12.412,7.428-12.412,7.428,5.561,7.428,12.412"
            transform="translate(-262.94 -135.597)"
            fill="#26242d"
          />
          <path
            d="M349.224,194.114c0,5.144-2.4,9.314-5.362,9.314s-5.362-4.171-5.362-9.314,2.4-9.314,5.362-9.314,5.362,4.171,5.362,9.314"
            transform="translate(-271.275 -148.099)"
            fill="#fff"
          />
          <path
            d="M142,30.5a6.1,6.1,0,0,0-.775-2.76c-.616-1.172-.338-2.185-.258-3.416a10.447,10.447,0,0,0-.119-2.86c-.258-1.152-1.033-1.509-1.768-2.343s-.933-1.41-1.887-1.688a5.623,5.623,0,0,1-1.331-.477c-.5-.278-1.152-.735-1.708-1.112-4.012-2.8-7.586-6.037-11.9-8.44a49.133,49.133,0,0,0-13.5-5.144A107.406,107.406,0,0,0,94.077.417C89.172.04,83.889,0,78.785,0S66.631.377,61.329.953a51.079,51.079,0,0,0-6.951,1.291,38.647,38.647,0,0,0-6.752,2.383C41.469,7.368,30.864,14.875,28.183,16.762a7.845,7.845,0,0,1-2.7,1.211A36.532,36.532,0,0,0,15.1,21.766c-.894.516-1.668,1.033-2.562,1.549s-1.887.854-2.9,1.311-2.185.814-3.158,1.291a14.3,14.3,0,0,0-2.482,1.489A4.085,4.085,0,0,0,2.266,29.79c-.139.953.238,2.085-.258,2.86a5.064,5.064,0,0,1-.874.775C.141,34.1.082,35.608.022,36.76-.1,40.017.379,43,.221,44.8a10.339,10.339,0,0,0-.06,3.317A4.455,4.455,0,0,0,1.65,50.9c.318.179.576.159.894.4s.4.437.715.576a1.748,1.748,0,0,0,.7.1H62.739a44.783,44.783,0,0,1,.914-8.54,27.221,27.221,0,0,1,3.356-8.123c3.892-5.739,9.155-4.468,10.923-3.1,3.078,2.4,4.29,6.971,4.29,10.665a25.839,25.839,0,0,1-1.192,8.262H122.7a.715.715,0,0,0,.6-.218c.3-.357-.616-3.237.119-7.586a22.377,22.377,0,0,1,2.2-6.951c1.172-2.165,3.237-5.481,6.137-5.263,3.634.278,5.7,4.786,6.256,7.467a22.749,22.749,0,0,1-.06,9.91c-.179,1.013.7.4,1.211.1a6.4,6.4,0,0,0,1.807-1.39,5.162,5.162,0,0,0,.675-2.681c.238-2.443.715-4.727,0-7.368-.6-2.2.437-4.409.377-6.633"
            transform="translate(-0.002)"
            fill="#15b9e0"
          />
          <path
            d="M141.6,100.814c-.6-2.2.437-4.409.377-6.633a6.1,6.1,0,0,0-.775-2.76c-.616-1.172-.338-2.185-.258-3.416a10.465,10.465,0,0,0-.119-2.86c-.258-1.152-1.033-1.509-1.768-2.343s-.933-1.41-1.887-1.688a5.623,5.623,0,0,1-1.331-.477c-.5-.278-1.152-.735-1.708-1.112s-4.826,1.827-11.28,1.609a260.57,260.57,0,0,0-50.265,3.654c-11.082,1.926-22.322,6.355-32.471,7.984A166.806,166.806,0,0,1,17.8,94.717c-10.7.1-15.491-1.509-15.53-1.251-.139.874.238,2.085-.258,2.86a5.084,5.084,0,0,1-.874.775c-.993.675-1.072,2.185-1.112,3.336-.119,3.257.357,6.236.2,8.043a10.318,10.318,0,0,0-.06,3.317,4.4,4.4,0,0,0,1.489,2.78c.318.179.576.159.894.4s.4.437.715.576a1.747,1.747,0,0,0,.7.1H62.739a44.8,44.8,0,0,1,.914-8.54,27.22,27.22,0,0,1,3.356-8.123c3.892-5.739,9.155-4.468,10.923-3.1,3.078,2.4,4.29,6.971,4.29,10.665a25.839,25.839,0,0,1-1.192,8.262H122.7a.715.715,0,0,0,.6-.218c.3-.357-.616-3.237.119-7.586a22.377,22.377,0,0,1,2.2-6.951c1.172-2.165,3.237-5.481,6.137-5.263,3.634.278,5.7,4.786,6.256,7.467a22.749,22.749,0,0,1-.06,9.91c-.179,1.013.7.4,1.211.1a5.411,5.411,0,0,0,1.807-1.39,5.162,5.162,0,0,0,.675-2.681c.238-2.443.715-4.727,0-7.368"
            transform="translate(-0.002 -63.696)"
            fill="#17aed2"
          />
          <path
            d="M145.97,61.577a8.385,8.385,0,0,0-4.051.755c-1.231.636-2.621,2.085-1.708,3.237.536.7,1.45,0,1.926-.338s3.416-2.224,4.032-2.681.933-.953-.2-.973"
            transform="translate(-112.129 -49.343)"
            fill="#15b9e0"
          />
          <path
            d="M216.436,134.825a26.433,26.433,0,0,0-3.416-.556,23.645,23.645,0,0,0-6.852.914c-4.488,1.013-14.557,3.4-15.51,3.634-1.43.357-.218.894.794,1.489,1.807,1.072,3.336,1.946,5.481,1.907a85.05,85.05,0,0,0,12.968-1.072,16.8,16.8,0,0,0,6.176-2.046,18.132,18.132,0,0,0,2.8-2.2,3.156,3.156,0,0,0,.775-1.072c.079-.338-2.86-.933-3.2-1.013"
            transform="translate(-152.269 -107.578)"
            fill="#fff"
          />
          <path
            d="M14.934,139.107c0-.3-.338-.655-.536-.933a3.992,3.992,0,0,1-.6-1.748c-.079-.655.119-2.324-.477-2.105a8.094,8.094,0,0,0-1.966,1.41c-.993.914-.874,1.986-.814,3.237.04.874.536,4.31,1.827,3.039.516-.516.894-.973,1.43-1.529s1.132-.993,1.132-1.35"
            transform="translate(-8.42 -107.63)"
            fill="#fff"
          />
          <path
            d="M47.046,251.769a4.176,4.176,0,0,0-2.562-.7H19.441c-1.748,0-2.423-.159-3.813,1.112s.4.993,1.192.993h31.1c.338,0,.477-.218.357-.457a4.244,4.244,0,0,0-1.231-.933"
            transform="translate(-12.113 -201.207)"
            fill="#26242d"
          />
          <path
            d="M50.443,161.382a20.932,20.932,0,0,0-3.356-2.681,6.976,6.976,0,0,0-4.151-.755c-3.2.1-7.765.278-11.26.278-3.178,0-5.3.02-8.281-.159-1.509-.1-2.324-.278-3.356.556a15.378,15.378,0,0,0-3.1,4.032c-.417.834-.1,2.363.536,4.071a54.375,54.375,0,0,0,2.76,5.878,3.672,3.672,0,0,0,2.979,1.35H41.943a4.07,4.07,0,0,0,3.2-1.489,91.91,91.91,0,0,0,5.839-7.606,2.529,2.529,0,0,0-.556-3.5"
            transform="translate(-13.424 -126.567)"
            fill="#26242d"
          />
          <path
            d="M687.9,98.625a3.05,3.05,0,0,0-1.152-2.244,3.572,3.572,0,0,0-.874-.735,1.819,1.819,0,0,0-1.251.278c-.4.218-2.125,1.132-2.323,1.629s.854,1.37,1.35,1.748,1.112,1.112,1.768,1.589,1.112.377,2.185.377c.417,0,.3-1.807.3-2.641"
            transform="translate(-546.778 -76.621)"
            fill="#fff"
          />
          <path
            d="M449.4,67.635c-.4-2.065-4.727-2.84-6.494-2.939-1.231-.079-2.622-.179-3.555.675a4.488,4.488,0,0,0-1.39,2.88,3.152,3.152,0,0,0,1.112,2.681c.794.6,3.058.536,4.151.4,2.006-.258,6.752-.715,6.2-3.694"
            transform="translate(-350.974 -51.807)"
            fill="#17aed2"
          />
          <path
            d="M201.569,24.855a11.346,11.346,0,0,1-2.224,2.244,6.071,6.071,0,0,1-2.741.218c-2.244.04-8.679-1.132-23.216-1.172-4.429-.02-13.584.218-18.43.536-1.589.119.139-1.251.735-1.748a121.613,121.613,0,0,1,10.724-7.547,53.245,53.245,0,0,1,6.057-3.237,16.234,16.234,0,0,1,5.779-1.787c2.3-.3,5.2-.477,7.408-.536a209.856,209.856,0,0,1,28.379.854c-.914.755-1.787,1.609-2.046,1.847-1.787,1.589-8.738,8.5-10.446,10.347m46.372.477a10.128,10.128,0,0,1-2.482,1.092,15.275,15.275,0,0,1-2.7.4c-4.111.338-8.2.675-12.313.953-2.363.159-8.3.636-13.147.933,2.244-.338,5.243-1.092,4.786-3.5-.4-2.065-4.727-2.84-6.494-2.939-1.231-.079-2.621-.179-3.555.675a4.488,4.488,0,0,0-1.39,2.88,3.152,3.152,0,0,0,1.112,2.681,2.82,2.82,0,0,0,1.271.437l-2.046.06a20.078,20.078,0,0,1-2.145-.139q-1.35-.159-2.7-.417c-.576-.1-1.907-.338-2.244-.834s1.807-2.82,2.324-3.356c2.264-2.383,4.528-4.965,7.05-7.189s4.746-3.773,7.845-4.171a37.774,37.774,0,0,1,10.685.357,35.46,35.46,0,0,1,9.771,3.078,70.256,70.256,0,0,1,9.056,5.322c.993.993-2.185,3.376-2.7,3.694"
            transform="translate(-123.66 -9.384)"
            fill="#26242d"
          />
        </g>
        <g transform="translate(269.566 75.143)" opacity="0.702">
          <rect width="20" height="6" transform="translate(0.434 13.857)" fill="#fff" />
          <rect width="20" height="7" transform="translate(13.434 19.857) rotate(-90)" fill="#fff" />
        </g>
        <g transform="translate(85 75.143)" opacity="0.702">
          <rect width="20" height="6" transform="translate(0 13.857)" fill="#fff" />
          <rect width="20" height="6" transform="translate(0 19.857) rotate(-90)" fill="#fff" />
        </g>
        <g transform="translate(85 16)" opacity="0.702">
          <rect width="20" height="6" fill="#fff" />
          <rect width="20" height="6" transform="translate(6) rotate(90)" fill="#fff" />
        </g>
        <g transform="translate(269.566 16)" opacity="0.702">
          <rect width="20" height="6" transform="translate(0.434)" fill="#fff" />
          <rect width="20" height="7" transform="translate(20.434) rotate(90)" fill="#fff" />
        </g>
      </g>
    </svg>
  );
}
