import React, { useEffect, useState, useRef } from 'react';
//import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Typography, Button, Grid, makeStyles, Link, Theme, styled } from '@material-ui/core';
//import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import StartCallButton from '../Buttons/StartCallButton/StartCallButton';
//import FlipCameraButton from '../MenuBar/FlipCameraButton/FlipCameraButton';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import { Steps } from '../../App';
//import ErrorDialog from '../../components/ErrorDialog/ErrorDialog';
//import { useAppState } from '../../state';
//import { Autorenew } from '@material-ui/icons';
//import useHeight from '../../hooks/useHeight/useHeight';
import './animation.css';
import useOrientation from './useOrientation';
import IconProcessing from './IconProcessing';
import IconRotatePhone from './IconRotatePhone';
import Icon360VideoWithDistance from './Icon360VideoWithDistance';
import ImageEntireVehicle from '../NotRecordingScreens/DamageAreaInstructionsScreen/ImageEntireVehicle';
import ImageZoomedInDamage from '../NotRecordingScreens/DamageAreaInstructionsScreen/ImageZoomedInDamage';
//import { constants } from 'perf_hooks';

const useStyles = makeStyles((theme: Theme) => ({
  instructions: {},
  instructionsHeader: {
    padding: '15px 35px 30px',
  },
  instructionsTitle: {
    color: '#ffffff',
    marginBottom: '1.5rem',
  },
  instructionsText: {
    fontSize: '1.3rem',
    lineHeight: '1.7rem',
    color: '#ffffff',
  },
  instructionsIconContainer: {
    width: '115px',
    display: 'flex',
    alignItems: 'center',
  },
  instructionsTextContainer: {
    width: 'calc(100% - 115px)',
    display: 'flex',
    alignItems: 'center',
  },
  carPositionImg: {
    width: 'calc(100% - 4px)',
  },
  cameraBody: {
    position: 'relative',
  },
  cameraScreen: {
    width: '100%',
    height: '100%',
  },
  cameraActions: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '100px',
    display: 'flex',
    alignItems: 'center',
  },
  cameraActionsPortrait: {
    boxSizing: 'border-box',
    width: '100%',
    top: 'auto',
    padding: '15px 0',
  },
  startStopButton: {
    margin: '0 auto',
  },
  orientationTitle: {
    color: '#ffffff',
    width: '180px',
    margin: '0 auto 50px',
    textAlign: 'center',
  },
  orientationNotice: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  orientationNoticeInner: {
    flex: '1',
    '& h3': {
      color: '#ffffff',
    },
  },
  noteText: {
    fontStyle: 'italic',
    color: '#ffffff',
    textAlign: 'center',
    marginTop: '1rem',
  },
  iconRotatePhone: {
    display: 'block',
    width: '160px',
    margin: '0 auto 0',
    fill: '#ffffff',
  },
  thinking: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
  },
  iconProcessing: {},
  wontRotateButton: {
    position: 'absolute',
    bottom: '10px',
    margin: '0 auto',
  },
  spinTransition: {
    loop: Infinity,
    ease: 'linear',
    duration: 1,
  },
  wontRotate: {
    width: '80px',
    transform: 'rotate(90deg)',
    transformOrigin: 'center center',
    margin: '45px auto 0',
    textAlign: 'left',
    fontSize: '.95rem',
    lineHeight: '1.1rem',
    color: '#aaaaaa',
  },
  distanceInstructions: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    width: '250px',
    textAlign: 'center',
    padding: '20px',
    borderRadius: '40px',
    background: 'rgba(255,202,64,0.8)',
    color: '#ffffff',
    fontSize: '1.5rem',
  },
  notInLandscape: {
    overflowY: 'hidden',
    overflowX: 'scroll',
    height: '100%',
    width: 42000 / window.innerHeight + 100 + 'vw',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row-reverse',
    '& .swivel-container': {
      alignSelf: 'flex-start',
      width: 42000 / window.innerHeight + 'vw',
    },
    '& .swivel': {
      width: window.innerHeight + 'px',
      height: 'auto',
      margin: '0 0 0 auto',
      transform: 'rotate(90deg) translateY(-100%)',
      transformOrigin: 'top left',
    },
    '& #camera': {
      height: 'auto',
      width: '100vw',
    },
    '& #distance-notice': {
      top: '50%',
      left: '50%',
      transform: 'rotate(90deg) translateX(-50%) translateY(-50%)',
      transformOrigin: 'top left',
    },
    '& #time': {
      top: '50%',
      right: '0',
      left: 'auto',
      transform: 'rotate(90deg) translateX(-50%) translateY(-30%)',
      transformOrigin: 'center center',
    },
  },
  hide: {
    display: 'none',
  },
  boundingBoxBorder: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    border: 'solid 60px rgba(0,0,0,0.9)',
    borderTopWidth: window.innerHeight / 6 + 'px',
    borderBottomWidth: window.innerHeight / 6 + 'px',
    borderRightWidth: window.innerWidth / 6 + 'px',
    borderLeftWidth: window.innerWidth / 6 + 'px',
  },
  boundingBoxMessageTop: {
    position: 'absolute',
    width: '100%',
    top: window.innerHeight / 6 - 25 + 'px',
    fontSize: '1.1rem',
    color: 'rgba(255,202,64)',
    textAlign: 'center',
    display: 'none',
  },
  boundingBoxMessageRight: {
    position: 'absolute',
    height: '100%',
    right: window.innerWidth / 6 - 25 + 'px',
    fontSize: '1.1rem',
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    color: 'rgba(255,202,64)',
    textAlign: 'center',
  },
  boundingBoxMessageBottom: {
    position: 'absolute',
    width: '100%',
    bottom: window.innerHeight / 6 - 25 + 'px',
    fontSize: '1.1rem',
    color: 'rgba(255,202,64)',
    textAlign: 'center',
    display: 'none',
  },
  boundingBoxMessageLeft: {
    position: 'absolute',
    height: '100%',
    left: window.innerWidth / 6 - 25 + 'px',
    fontSize: '1.1rem',
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    color: 'rgba(255,202,64)',
    textAlign: 'center',
  },
}));

const Main = styled('main')(({ theme }: { theme: Theme }) => ({}));

const CameraBody = styled('section')(() => ({
  position: 'relative',
  height: '100vh',
}));

const ThinkingContainer = styled('div')(() => ({
  position: 'absolute',
  display: 'none',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: 'rgba(0,0,0,0.6)',
}));

const BoundingBox = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}));

const Thinking = styled('div')(() => ({
  width: '80px',
  height: '80px',
}));

const RecordingTime = styled('div')({
  position: 'absolute',
  top: '10px',
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '2px 5px',
  background: 'rgba(0,0,0,0.4)',
  textAlign: 'center',
  color: '#ffffff',
  fontSize: '1.3rem',
  fontFamily: 'monospace',
  letterSpacing: '2px',
  borderRadius: '2px',
  fontWeight: 'bold',
});

const DeniedDeviceMotion = styled('div')({
  display: 'none',
  position: 'absolute',
  background: '#ffffff',
  padding: '1.5rem',
  '& h4': {
    paddingBottom: '1rem',
  },
});

const DeviceMotionAskIntro = styled('div')({
  display: 'none',
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '430px',
  background: '#ffffff',
  padding: '1rem 1.5rem 1.5rem',
  transform: 'rotate(90deg) translateX(-50%) translateY(-50%)',
  transformOrigin: '0 0',
  borderRadius: '15px',
  boxShadow: '0 0 200px rgba(0,0,0,0.1)',
  'z-index': '20',
  '& h4': {
    fontSize: '1.8rem',
    paddingBottom: '1rem',
  },
  '& button': {
    display: 'block',
    margin: '2rem auto 0',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  '& ul': {
    maxWidth: '4300px',
    margin: '2rem auto .5rem',
    paddingLeft: '0',
    listStyleType: 'none',
    textAlign: 'left',
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
  },
  '& li': {
    marginTop: '1rem',
  },
});

const InstructionImageContainer = styled('div')({
  marginTop: '1rem',
  '& svg': {
    width: '100%',
  },
});

interface CameraProps {
  setStep: (step: Steps) => void;
  children: React.ReactNode;
}

const VideoCamera = ({ setStep, children }: CameraProps) => {
  //const { error, setError } = useAppState();
  const landscape = useOrientation() === 'LANDSCAPE';
  const roomState = useRoomState();
  const classes = useStyles();
  const [actionsRight, setActionsRight] = useState('0');
  const [forcedOrientation, setForcedOrientation] = useState(false);
  const motionSwitchToCamera = useRef(false);
  //const showedInstructions = useRef(false);
  //const showedInstructionsTimer = useRef<any>(null);
  let isAndroid = false;
  let needMotionPermissions = true;
  //let preventAutoScroll = false;
  console.log('recreated Camera');

  // When we start recording we don't want the rotate your phone screen to flicker on HACK
  useEffect(() => {
    if (window.appState.recordingVideo) {
      setForcedOrientation(true);
    }
  }, []);

  if (/Android/.test(navigator.userAgent)) {
    isAndroid = true;
    needMotionPermissions = false;
  }

  var cameraActionsStyle = {
    right: actionsRight,
  };

  useEffect(() => {
    let body = document.getElementsByTagName('body');
    if (body) {
      body[0].style.backgroundColor = '#000000';
    }
  });

  useEffect(() => {
    if (roomState !== 'disconnected') {
      console.log('scroll to the camera since we are recording');
      window.scrollTo(0, document.body.scrollHeight);
      var startTime = new Date().getTime();
      const interval = setInterval(() => {
        var currentTime = new Date().getTime();
        var distance = currentTime - startTime;
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        var currentTimeString =
          (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds);

        // Update the time display
        var timeEl = document.getElementById('time');
        if (timeEl) {
          timeEl.innerHTML = currentTimeString;
        }
      }, 1000);
      return () => clearInterval(interval);
    }

    const adjustCameraActions = () => {
      var pageWidth = document.body.offsetWidth;
      var camera = document.getElementById('camera');
      if (camera) {
        var cameraWidth = camera.offsetWidth;
        if (pageWidth - cameraWidth >= 125) {
          //dsetCameraMargins('0 0 0 35px');
          var newWidth = pageWidth - (cameraWidth + 35);
          //setActionsWidth(newWidth + 'px');
          setActionsRight('-' + (newWidth + 50) + 'px');
        }
      }

      var box = document.getElementById('bounding-box-border');
      if (box) {
        box.style.borderTopWidth = window.innerHeight / 6 + 'px';
        box.style.borderBottomWidth = window.innerHeight / 6 + 'px';
        box.style.borderRightWidth = window.innerWidth / 6 + 'px';
        box.style.borderLeftWidth = window.innerWidth / 6 + 'px';
      }

      var topText = document.getElementById('bounding-box-border-top-text');
      if (topText) {
        topText.style.top = window.innerHeight / 6 - 25 + 'px';
      }
      var rightText = document.getElementById('bounding-box-border-right-text');
      if (rightText) {
        rightText.style.right = window.innerWidth / 6 - 25 + 'px';
      }
      var bottomText = document.getElementById('bounding-box-border-bottom-text');
      if (bottomText) {
        bottomText.style.bottom = window.innerHeight / 6 - 25 + 'px';
      }
      var leftText = document.getElementById('bounding-box-border-left-text');
      if (leftText) {
        leftText.style.left = window.innerWidth / 6 - 25 + 'px';
      }
    };

    adjustCameraActions();

    window.addEventListener('resize', adjustCameraActions);
    return () => {
      window.removeEventListener('resize', adjustCameraActions);
    };
  }, [roomState]);

  useEffect(() => {
    var topText = document.getElementById('bounding-box-border-top-text');
    var rightText = document.getElementById('bounding-box-border-right-text');
    var bottomText = document.getElementById('bounding-box-border-bottom-text');
    var leftText = document.getElementById('bounding-box-border-left-text');

    if (landscape) {
      if (topText) {
        topText.style.display = 'block';
      }

      if (rightText) {
        rightText.style.display = 'none';
      }

      if (bottomText) {
        bottomText.style.display = 'block';
      }

      if (leftText) {
        leftText.style.display = 'none';
      }

      var swivel = document.getElementById('swivel');
      if (swivel) {
        swivel.style.width = 'auto';
      }
    } else {
      if (topText) {
        topText.style.display = 'none';
      }

      if (rightText) {
        rightText.style.display = 'block';
      }

      if (bottomText) {
        bottomText.style.display = 'none';
      }

      if (leftText) {
        leftText.style.display = 'block';
      }

      var cameraPage = document.getElementById('camera-page-main');
      if (cameraPage) {
        var swivel = document.getElementById('swivel');
        if (swivel) {
          swivel.style.width = cameraPage.offsetHeight + 'px';
        }
      }
    }
  }, [landscape, forcedOrientation]);

  // Scroll to the top of the instuctions when we show the camera
  useEffect(() => {
    if (roomState === 'disconnected') {
      window.scrollTo(document.body.scrollWidth, 0);
    }
  }, [landscape, forcedOrientation]);

  useEffect(() => {
    let timeout: any;
    if (window.DeviceMotionEvent) {
      //alert('supports orientation');
      window.addEventListener(
        'devicemotion',
        function(event: any) {
          // We don't want this to mess with the behavior if the device is already in landscape
          //
          // sensitive than the device native screen rotation
          if (window.innerWidth < window.innerHeight) {
            //console.log('motion function triggered');
            //if (Math.abs(event.accelerationIncludingGravity.x) > 6.5) {
            if (
              Math.abs(event.accelerationIncludingGravity.x) + Math.abs(event.accelerationIncludingGravity.z) > 12.5 ||
              Math.abs(event.accelerationIncludingGravity.x) > 7.4
            ) {
              //console.log('preventAutoScroll is ' + preventAutoScroll);
              if (!motionSwitchToCamera.current) {
                clearTimeout(timeout);
                if (window.appState.recordingVideo) {
                  console.log('keep forced orientation because recording');
                  setForcedOrientation(true);
                } else {
                  timeout = setTimeout(function() {
                    console.log('forcing orientation');
                    setForcedOrientation(true);
                  }, 100);
                }
                motionSwitchToCamera.current = true;
              }
            } else {
              if (motionSwitchToCamera.current) {
                clearTimeout(timeout);
                timeout = setTimeout(function() {
                  console.log('remove forced orientation');
                  setForcedOrientation(false);
                }, 500);
                motionSwitchToCamera.current = false;
              }
            }
          } else {
            clearTimeout(timeout);
            setForcedOrientation(false);
            motionSwitchToCamera.current = false;
          }
        },
        false
      );
    } else {
      console.log('DeviceMotionEvent is not supported');
    }
  }, []);

  /*
  function motion(event: any) {
    console.log('motion function triggered');
    var elOrientationX = document.getElementById('orientationx');
    var elOrientationY = document.getElementById('orientationy');
    var elOrientationZ = document.getElementById('orientationz');
    if (elOrientationX) {
      elOrientationX.textContent = 'X: ' + event.accelerationIncludingGravity.x;
    }
    if (elOrientationY) {
      elOrientationY.textContent = 'Y: ' + event.accelerationIncludingGravity.y;
    }
    if (elOrientationZ) {
      elOrientationZ.textContent = 'Z: ' + event.accelerationIncludingGravity.z;
    }

    //if (Math.abs(event.accelerationIncludingGravity.x) > 6.5) {
    if (
      Math.abs(event.accelerationIncludingGravity.x) + Math.abs(event.accelerationIncludingGravity.z) > 12.5 ||
      Math.abs(event.accelerationIncludingGravity.x) > 7.4
    ) {
      //console.log('preventAutoScroll is ' + preventAutoScroll);
      if (!switchToCamera) {
        clearTimeout(timeout);
        if (window.appState.recordingVideo) {
          setForcedOrientation(true);
        } else {
          timeout = setTimeout(function() {
            setForcedOrientation(true);
          }, 100);
        }

        switchToCamera = true;
      }
    } else {
      if (switchToCamera) {
        clearTimeout(timeout);
        timeout = setTimeout(function() {
          setForcedOrientation(false);
        }, 100);
        switchToCamera = false;
      }
    }
  }
  */

  const motionPermissionsIntro = () => {
    console.log('show the motion permissions intro');
    let deviceMotionAskIntro = document.getElementById('device-motion-ask-intro');
    if (deviceMotionAskIntro) {
      deviceMotionAskIntro.style.display = 'block';
    }
  };

  const askForMotionPermissions = () => {
    let deviceMotionAskIntro = document.getElementById('device-motion-ask-intro');
    if (deviceMotionAskIntro) {
      deviceMotionAskIntro.style.display = 'none';
    }
    if (window.DeviceMotionEvent && typeof DeviceMotionEvent.requestPermission === 'function') {
      console.log('I guess this is iOS 13+');
      DeviceMotionEvent.requestPermission()
        .then(permissionState => {
          console.log('Permission state is ', permissionState);
          if (permissionState === 'denied') {
            console.log('device motion permissions are denied');

            let deniedDeviceMotion = document.getElementById('denied-device-motion-notice');
            if (deniedDeviceMotion) {
              deniedDeviceMotion.style.display = 'block';
            }
          }
        })
        .catch(console.error);
    } else {
      console.log("Non iOS13 can we track? Shouldn't need to click the won't rotate");
      // handle regular non iOS 13+ devices
    }
  };
  //<ErrorDialog dismissError={() => setError(null)} error={error} />
  //<h3 id="orientationx"></h3>
  //<h3 id="orientationy"></h3>
  //<h3 id="orientationz"></h3>
  return (
    <>
      <DeniedDeviceMotion id="denied-device-motion-notice">
        <Typography variant="h4">App will not work without motion permissions</Typography>
        <Typography variant="body1">
          This app will not work without access to your devices motion. To use this app you must either close this tab
          and re-open the app in another, or restart your browser.
        </Typography>
      </DeniedDeviceMotion>
      <DeviceMotionAskIntro id="device-motion-ask-intro">
        <Typography variant="h4">Motion permissions required</Typography>
        <Typography variant="body1">
          This app uses your devices motion to assist with recording the video. Tap "Allow" when asked to allow
          permissions.
        </Typography>
        <Button onClick={askForMotionPermissions} variant="contained" color="primary">
          Request Motion Permissions
        </Button>
      </DeviceMotionAskIntro>
      {landscape || forcedOrientation || (roomState !== 'disconnected' && (landscape || forcedOrientation)) ? (
        <Main id="camera-page-main" className={landscape ? '' : classes.notInLandscape}>
          <div className="swivel-container">
            <div id="swivel" className="swivel">
              <section className={classes.instructions}>
                <header className={classes.instructionsHeader}>
                  <Typography variant="h4" className={classes.instructionsTitle}>
                    Video Instructions
                  </Typography>
                  <Grid container justify="center">
                    <Grid item className={classes.instructionsIconContainer}>
                      <Icon360VideoWithDistance />
                    </Grid>
                    <Grid item className={classes.instructionsTextContainer}>
                      {window.job.experienceType == 'four_corners' ? (
                        <Typography variant="body1" className={classes.instructionsText}>
                          Scroll down and record a video with the entire vehicle in frame. KEEP THE ENTIRE VEHICLE IN
                          FRAME WHILE RECORDING.
                        </Typography>
                      ) : (
                        <Typography variant="body1" className={classes.instructionsText}>
                          Scroll down and start with the clearest view of the damage, record a video with the entire
                          vehicle in frame. KEEP THE ENTIRE VEHICLE IN FRAME WHILE RECORDING.
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </header>
              </section>
            </div>
          </div>
          <CameraBody id="camera">
            <div className={classes.cameraScreen}>{children}</div>
            <BoundingBox id="bounding-box">
              <div className={classes.boundingBoxBorder} id="bounding-box-border"></div>
              <Typography variant="h5" className={classes.boundingBoxMessageTop} id="bounding-box-border-top-text">
                KEEP ENTIRE VEHICLE OUTLINE INSIDE THIS BOX
              </Typography>
              <Typography variant="h5" className={classes.boundingBoxMessageRight} id="bounding-box-border-right-text">
                KEEP ENTIRE VEHICLE OUTLINE INSIDE THIS BOX
              </Typography>
              <Typography
                variant="h5"
                className={classes.boundingBoxMessageBottom}
                id="bounding-box-border-bottom-text"
              >
                KEEP ENTIRE VEHICLE OUTLINE INSIDE THIS BOX
              </Typography>
              <Typography variant="h5" className={classes.boundingBoxMessageLeft} id="bounding-box-border-left-text">
                KEEP ENTIRE VEHICLE OUTLINE INSIDE THIS BOX
              </Typography>
            </BoundingBox>
            <RecordingTime id="time">00:00</RecordingTime>
            <div
              style={cameraActionsStyle}
              className={classes.cameraActions + ' ' + (landscape ? '' : classes.cameraActionsPortrait)}
            >
              <div className={classes.startStopButton}>
                {roomState === 'disconnected' ? <StartCallButton /> : <EndCallButton setStep={setStep} />}
              </div>
            </div>
            <Typography
              id="distance-notice"
              variant="h4"
              className={classes.distanceInstructions + ' ' + (roomState !== 'disconnected' ? classes.hide : null)}
            >
              KEEP ENTIRE VEHICLE OUTLINE INSIDE THIS BOX
            </Typography>
            <ThinkingContainer id="thinking">
              <Thinking className={classes.thinking}>
                <IconProcessing className="rotating" />
              </Thinking>
            </ThinkingContainer>
          </CameraBody>
        </Main>
      ) : (
        <div className={classes.orientationNotice}>
          <div className={classes.orientationNoticeInner}>
            <Typography variant="h4" className={classes.orientationTitle}>
              360 Video
            </Typography>
            <IconRotatePhone className={classes.iconRotatePhone} />
            <Typography variant="body1" className={classes.noteText}>
              Turn your phone sideways and point the camera toward the vehicle.
            </Typography>
            {needMotionPermissions ? (
              <div className={classes.wontRotate}>
                If your screen will not rotate, tap <Link onClick={motionPermissionsIntro}>here.</Link>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default VideoCamera;
