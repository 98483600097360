import React from 'react';
//import { makeStyles, Theme, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core';
//import Swoosh from './swoosh';
//import VideoLogo from './VideoLogo';
//import TwilioLogo from './TwilioLogo';
//import { useAppState } from '../../state';
//import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    //display: 'flex',
    //alignItems: 'center',
    //justifyContent: 'center',
    height: '100%',
    background: '#ffffff',
  },
  backgroundDark: {
    //display: 'flex',
    //alignItems: 'center',
    //justifyContent: 'center',
    height: '100%',
    title: {
      color: '#ffffff',
    },
  },
  container: {
    position: 'relative',
    //flex: '1',
    height: '100%', // Remove if flex
    [theme.breakpoints.down('sm')]: {},
  },
  innerContainer: {
    //display: 'flex',
    height: '100%', // remove if flex
    width: '100%',
    //maxWidth: '600px',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto',
  },
  content: {
    //background: 'white',
    height: '100%', // Remove if flex
    width: '100%',
    //flex: 1,
  },
  title: {
    margin: '1em 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '1.1rem',
    },
  },
  subContentContainer: {
    position: 'absolute',
    marginTop: '1em',
    width: '100%',
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
  dark?: boolean;
  subContent?: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();
  //const { user } = useAppState();
  //const location = useLocation();

  return (
    <div className={props.dark ? classes.backgroundDark : classes.background}>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.content}>{props.children}</div>
        </div>
        {props.subContent && <div className={classes.subContentContainer}>{props.subContent}</div>}
      </div>
    </div>
  );
};

export default IntroContainer;
