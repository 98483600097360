import React, { SVGProps } from 'react';

export default function IconDriverBackCorner(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.458 87.379" {...props}>
      <defs>
        <clipPath id="icon-driver-back-corner-clip">
          <rect width="22.366" height="45.698" transform="translate(0.002)" fill="none" />
        </clipPath>
      </defs>
      <g transform="translate(-3206.002 1190.534)">
        <g transform="translate(0 -103.853)">
          <path
            d="M10.973,43.716h0A8.422,8.422,0,0,1,2.55,35.294V15.7a8.422,8.422,0,0,1,8.422-8.422h0A8.422,8.422,0,0,1,19.4,15.7v19.59a8.422,8.422,0,0,1-8.422,8.422"
            transform="translate(3206 -1045)"
            fill="#26242d"
          />
          <g transform="translate(3206 -1045)">
            <g clip-path="url(#icon-driver-back-corner-clip)">
              <path
                d="M1.756,32.868S-.4,45.7,11.179,45.7c11.23,0,9.429-12.83,9.429-12.83s2.2-.4,1.682-1.958c0,0-.444-.432-2.285.555l.126-11.112a13.122,13.122,0,0,0,.527-4.92c0-13.993-18.941-13.993-18.941,0a13.163,13.163,0,0,0,.527,4.92l.124,11.112C.52,30.478.083,30.91.083,30.91c-.533,1.556,1.671,1.958,1.671,1.958M11.123,8.7a9.407,9.407,0,0,1,6.345,3.781V17s-2.483-4.646-6.443-4.646-6.184,4.779-6.184,4.779L4.75,12.475A9.494,9.494,0,0,1,11.123,8.7M4.931,30.473a9.594,9.594,0,0,0,6.059,1.353A9.92,9.92,0,0,0,17.268,30.3l1.777,4.142A9.9,9.9,0,0,1,11.1,37.587a9.826,9.826,0,0,1-7.936-3.2Z"
                fill="#15b9e0"
              />
            </g>
          </g>
          <path
            d="M18.068,41.088c.446-.212,2.058-1.006.364.88a8.856,8.856,0,0,1-1.206,1.1c-1.293,1.057.4-1.768.842-1.98"
            transform="translate(3206 -1045)"
            fill="#fff"
          />
          <path
            d="M4.289,41.088c-.446-.212-2.058-1.006-.365.88a8.856,8.856,0,0,0,1.206,1.1c1.294,1.057-.4-1.768-.841-1.98"
            transform="translate(3206 -1045)"
            fill="#fff"
          />
        </g>
        <path
          d="M20.372,47.03a.5.5,0,0,0-.5.5v4a.5.5,0,1,0,1,0v-4a.5.5,0,0,0-.5-.5m0,8a.5.5,0,0,0-.5.5v4a.5.5,0,1,0,1,0v-4a.5.5,0,0,0-.5-.5m0,8a.5.5,0,0,0-.5.5v4a.5.5,0,1,0,1,0v-4a.5.5,0,0,0-.5-.5m0,8a.5.5,0,0,0-.5.5v4a.5.5,0,1,0,1,0v-4a.5.5,0,0,0-.5-.5m0,8a.5.5,0,0,0-.5.5v4a.5.5,0,1,0,1,0v-4a.5.5,0,0,0-.5-.5"
          transform="translate(3206 -1229.827)"
          fill="#fff"
        />
        <path
          d="M48.767,47.031a.5.5,0,0,0-.354.146l-28.394,28.4a.5.5,0,0,0,.707.707L49.121,47.885a.5.5,0,0,0-.354-.854"
          transform="translate(3206 -1222.224)"
          fill="#fff"
        />
        <path
          d="M34.228,69.664l-.707.707a15,15,0,0,0-11.3-5.14v-1a16,16,0,0,1,12,5.433"
          transform="translate(3206 -1233.369)"
          fill="#fff"
        />
        <path
          d="M40.842,80.247a2.469,2.469,0,1,1,2.469-2.469,2.472,2.472,0,0,1-2.469,2.469m0-3.938a1.469,1.469,0,1,0,1.469,1.469,1.471,1.471,0,0,0-1.469-1.469"
          transform="translate(3206 -1260.514)"
          fill="#fff"
        />
        <path
          d="M29.793,81.319H28.412v1.916H26.405l2.379-4.7H27.256L24.808,83.4l-.027,1.136h3.631v1.795h1.381V84.536h1.058v-1.3H29.793Z"
          transform="translate(3206 -1260.514)"
          fill="#fff"
        />
        <path
          d="M34.088,81.27a4.347,4.347,0,0,0-.7.051l.159-1.479h3.205V78.53H32.414l-.434,3.858.33.422.169-.061a4.4,4.4,0,0,1,1.378-.2c.678,0,1.818.171,1.818,1.319,0,1.094-1.066,1.257-1.7,1.257a4.714,4.714,0,0,1-1.863-.392L31.8,84.58l-.159,1.35.171.073a5.842,5.842,0,0,0,2.259.435c1.879,0,3.047-1.017,3.047-2.652,0-1.5-1.215-2.516-3.026-2.516"
          transform="translate(3206 -1260.514)"
          fill="#fff"
        />
        <path
          d="M65.46,83.125h-2.7l-.28-.923s-.784-1.747-1.746-1.747H57.487c-.964,0-1.746,1.747-1.746,1.747l-.28.923H52.645a1,1,0,0,0-1,1v6.642a1,1,0,0,0,1,1H65.46a1,1,0,0,0,1-1V84.125a1,1,0,0,0-1-1"
          transform="translate(3206 -1270.989)"
          fill="#fff"
        />
        <path
          d="M59.053,83.125a3.7,3.7,0,1,1-3.7,3.7,3.7,3.7,0,0,1,3.7-3.7"
          transform="translate(3206 -1270.989)"
          fill="#fff"
        />
        <path
          d="M59.053,90.533a3.7,3.7,0,1,1,3.7-3.7,3.709,3.709,0,0,1-3.7,3.7m0-6.408a2.7,2.7,0,1,0,2.7,2.7,2.707,2.707,0,0,0-2.7-2.7"
          transform="translate(3206 -1270.989)"
        />
        <path
          d="M63.373,84.359a.617.617,0,1,1-.617.617.617.617,0,0,1,.617-.617"
          transform="translate(3206 -1270.989)"
        />
        <path
          d="M53.5,80.656h1.235a.617.617,0,0,1,.617.617h0a.617.617,0,0,1-.617.617H53.5a.617.617,0,0,1-.617-.617h0a.617.617,0,0,1,.617-.617"
          transform="translate(3206 -1270.989)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
