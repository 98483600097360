import React from 'react';
//import { makeStyles, Typography, Grid, Button, Theme, Hidden } from '@material-ui/core';
//import { makeStyles, Theme } from '@material-ui/core';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
//import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../../../App';
//import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
//import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
//import { useAppState } from '../../../state';
//import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
// FlipCameraButton from '../../MenuBar/FlipCameraButton/FlipCameraButton';
import VideoCamera from '../../Camera/VideoCamera';

interface DeviceSelectionScreenProps {
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({ setStep }: DeviceSelectionScreenProps) {
  //const classes = useStyles();
  //const { getToken, isFetching } = useAppState();
  /*
  const { connect, isAcquiringLocalTracks, isConnecting } = useVideoContext();

  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  const handleJoin = () => {
    getToken(name, roomName).then(token => connect(token));
  };
  */
  return (
    <>
      <VideoCamera setStep={setStep}>
        <LocalVideoPreview />
      </VideoCamera>
    </>
  );

  /*
  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Join {roomName}
      </Typography>

      <Grid container justify="center">
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
              <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
            </Hidden>
            <SettingsMenu mobileButtonClass={classes.mobileButton} />
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid container direction="column" justify="space-between" style={{ height: '100%' }}>
            <div>
              <Hidden smDown>
                <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
                <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
              </Hidden>
            </div>
            <div className={classes.joinButtons}>
              <Button variant="outlined" color="primary" onClick={() => setStep(Steps.roomNameStep)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                data-cy-join-now
                onClick={handleJoin}
                disabled={disableButtons}
              >
                Join Now
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
  */
}
