import React from 'react';

export default function AvatarIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="84.003" height="122.472" viewBox="0 0 84.003 122.472">
      <g transform="translate(-255 -8)">
        <g transform="translate(10 -17)">
          <g transform="translate(-297 202)">
            <path
              d="M42,0A42,42,0,0,1,84,42c0,23.2-42,64.471-42,64.471S0,65.2,0,42A42,42,0,0,1,42,0Z"
              transform="translate(542 -161)"
              fill="#fff"
            />
            <circle cx="14" cy="14" r="14" transform="translate(570 -133)" fill="#fff" />
          </g>
        </g>
        <text
          transform="translate(283 106)"
          fill="#ed1768"
          fontSize="100"
          fontFamily="HelveticaNeue-Bold, Helvetica Neue"
          fontWeight="700"
        >
          <tspan x="0" y="0">
            !
          </tspan>
        </text>
      </g>
    </svg>
  );
}
