import React, { useEffect, useState, useRef } from 'react';
import { Typography, Button, Grid, makeStyles, Link, Theme, styled } from '@material-ui/core';
import { Steps } from '../../App';
import getSecret from '../../Secret';
import './animation.css';
import { useLocation } from 'react-router-dom';
import useOrientation from './useOrientation';
import IconProcessing from './IconProcessing';
import IconRotatePhone from './IconRotatePhone';
import IconArrowDown from './IconArrowDown';
import IconDriverFrontCorner from './IconDriverFrontCorner';
import IconPassengerFrontCorner from './IconPassengerFrontCorner';
import IconPassengerBackCorner from './IconPassengerBackCorner';
import IconDriverBackCorner from './IconDriverBackCorner';
import imageAcceptablePhotoExample from './CarImages/acceptable-photo-example@2x.jpg';
import imageNotAcceptablePhotoExample from './CarImages/not-acceptable-photo-example@2x.jpg';
import imageAcceptableCornerPhotoExample from './CarImages/photo-example-good-corner-driveway.jpg';
import imageNotAcceptableCornerPhotoExample from './CarImages/photo-example-bad-corner-driveway.jpg';
import imageFlatbedAcceptablePhotoExample from './CarImages/flatbed-correct.jpg';
import imageFlatbedNotAcceptablePhotoExample from './CarImages/flatbed-incorrect.jpg';
//import Detection from './Detection';
import { initVehicleDetection, detectVehicle } from './DetectVehicle';
import { SportsRugbySharp } from '@material-ui/icons';
import trackEvent from '../../utils/trackEvent/trackEvent';
import { useCookies } from 'react-cookie';
import { classicNameResolver } from 'typescript';
import insp, { install } from 'inspectlet-es';

const useStyles = makeStyles((theme: Theme) => ({
  skipKeyButtonContainter: {
    position: 'absolute',
    bottom: '30px',
    left: '35px',
  },
  skipKeyPhotoButton: {
    padding: '1.2rem 2rem',
    borderRadius: '40px',
    background: '#ffffff',
    color: '#150F1A',
  },
  instructions: {},
  instructionsHeader: {
    padding: '15px 35px 30px',
  },
  instructionsTitle: {
    color: '#ffffff',
    marginBottom: '1.5rem',
  },
  instructionsText: {
    fontSize: '1.3rem',
    lineHeight: '1.7rem',
    color: '#ffffff',
  },
  instructionsIconContainer: {
    boxSizing: 'border-box',
    width: '80px',
    paddingRight: '20px',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: '100%',
    },
  },
  instructionsTextContainer: {
    width: 'calc(100% - 115px)',
    display: 'flex',
    alignItems: 'center',
  },
  carPositionImg: {
    width: 'calc(100% - 4px)',
  },
  cameraBody: {
    position: 'relative',
  },
  cameraScreen: {
    width: '100%',
    height: '100%',
  },
  cameraActions: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '100px',
    display: 'flex',
    alignItems: 'center',
  },
  cameraActionsPortrait: {
    boxSizing: 'border-box',
    width: '100%',
    top: 'auto',
    padding: '15px 0',
  },
  startStopButton: {
    margin: '0 auto',
  },
  thinking: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
  },
  iconProcessing: {},
  spinTransition: {
    loop: Infinity,
    ease: 'linear',
    duration: 1,
  },
  distanceInstructions: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    width: '250px',
    textAlign: 'center',
    padding: '20px',
    borderRadius: '40px',
    background: 'rgba(255,202,64,0.8)',
    color: '#ffffff',
    fontSize: '1.5rem',
  },
  notInLandscape: {
    overflowY: 'hidden',
    overflowX: 'scroll',
    height: '100%',
    width: 42000 / window.innerHeight + 100 + 'vw',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row-reverse',
    '& .swivel-container': {
      alignSelf: 'flex-start',
      width: 42000 / window.innerHeight + 'vw',
    },
    '& .swivel': {
      width: window.innerHeight + 'px',
      height: 'auto',
      margin: '0 0 0 auto',
      transform: 'rotate(90deg) translateY(-100%)',
      transformOrigin: 'top left',
    },
    '& #camera': {
      height: 'auto',
      width: '100vw',
    },
    '& #distance-notice': {
      top: '50%',
      left: '50%',
      transform: 'rotate(90deg) translateX(-50%) translateY(-50%)',
      transformOrigin: 'top left',
    },
    '& #time': {
      top: '50%',
      right: '0',
      left: 'auto',
      transform: 'rotate(90deg) translateX(-50%) translateY(-30%)',
      transformOrigin: 'center center',
    },
    '& #camera-actions': {
      transform: 'rotate(90deg) translateY(-100%)',
      transformOrigin: 'top left',
    },
    '& #visual-directions': {
      transform: 'rotate(90deg) translateY(-50%) translateX(-50%)',
      left: '50%',
      transformOrigin: 'top left',
    },
    // CameraNotifications
    '& #camera-notifications': {
      transform: 'rotate(90deg)',
      left: '100%',
      transformOrigin: 'top left',
    },
    '& #thinking': {
      transform: 'rotate(90deg)',
      left: '100%',
      transformOrigin: 'top left',
    },
    '& #photo-under-review': {
      transform: 'rotate(90deg)',
      left: '100%',
      transformOrigin: 'top left',
    },
  },
  hide: {
    display: 'none',
  },
}));

const Main = styled('main')(({ theme }: { theme: Theme }) => ({}));

const OrientationNotice = styled('div')(() => ({
  position: 'absolute',
  display: 'flex',
  top: '0',
  'z-index': '11',
  height: '100%',
  alignItems: 'center',
  background: '#000000',
  '& .orientation-title': {
    color: '#ffffff',
    width: '180px',
    margin: '0 auto 50px',
    textAlign: 'center',
  },
  '& .orientation-notice-inner': {
    flex: '1',
  },
  '& h3': {
    color: '#ffffff',
  },
  '& .note-text': {
    fontStyle: 'italic',
    color: '#ffffff',
    textAlign: 'center',
    marginTop: '1rem',
    padding: '0 2rem',
  },
  '& .icon-rotate-phone': {
    display: 'block',
    width: '160px',
    margin: '0 auto 0',
    fill: '#ffffff',
  },
  '& .wont-rotate-button': {
    position: 'absolute',
    bottom: '10px',
    margin: '0 auto',
  },
  '& .wont-rotate': {
    width: '80px',
    transform: 'rotate(90deg)',
    transformOrigin: 'center center',
    margin: '45px auto 0',
    textAlign: 'left',
    fontSize: '1.1rem',
    lineHeight: '1.2rem',
    color: '#ffffff',
  },
}));

const CameraBody = styled('section')(() => ({
  position: 'relative',
  height: '100vh',
  '& video': {
    width: '100%',
    height: '100%',
    //background: '#004400',
    // TODO Put back for zoom transform: 'scale(1.35)',
    //transformOrigin: '0 0',
  },
  '& #photo-work-space': {
    position: 'absolute',
    top: '0',
    //'z-index': '9',
    'z-index': '-1',
    //background: 'rgba(0,45,100,0.4)',
  },
  '& #ancillary-captured-picture-canvas': {
    transform: 'scale(.5)',
    transformOrigin: '0% 0%',
  },
  '& #canvas-container': {
    position: 'absolute',
    'z-index': '-1',
    top: '0',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    '& #detection-canvas': {
      'z-index': '100',
      background: 'rgba(45,45,45,0.4)',
    },
    '& canvas': {
      position: 'absolute',
      top: '0',
    },
    '& #captured-picture-canvas': {
      transform: 'scale(.5)',
      transformOrigin: '0% 0%',
      /* TODO put back for zoom
      transform: 'scale(.675)',
      transformOrigin: '-27% -27%',
      */
    },
  },
}));

const ThinkingContainer = styled('div')(() => ({
  position: 'absolute',
  'z-index': '100',
  display: 'none',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: 'rgba(0,0,0,0.6)',
  '& #thinking-text': {
    padding: '3rem',
    textAlign: 'center',
    color: '#ffffff',
  },
}));

//<Thinking
const Thinking = styled('div')(() => ({
  width: '80px',
  height: '80px',
}));

const DeniedDeviceMotion = styled('div')({
  display: 'none',
  position: 'absolute',
  'z-index': '12',
  background: '#ffffff',
  padding: '1.5rem',
  '& h4': {
    paddingBottom: '1rem',
  },
});

const DeviceMotionAskIntro = styled('div')({
  display: 'none',
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '430px',
  background: '#ffffff',
  padding: '1rem 1.5rem 1.5rem',
  transform: 'rotate(90deg) translateX(-50%) translateY(-50%)',
  transformOrigin: '0 0',
  borderRadius: '15px',
  boxShadow: '0 0 200px rgba(0,0,0,0.1)',
  'z-index': '20',
  '& h4': {
    fontSize: '1.8rem',
    paddingBottom: '1rem',
  },
  '& button': {
    display: 'block',
    margin: '2rem auto 0',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  '& ul': {
    maxWidth: '4300px',
    margin: '2rem auto .5rem',
    paddingLeft: '0',
    listStyleType: 'none',
    textAlign: 'left',
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
  },
  '& li': {
    marginTop: '1rem',
  },
});

const ZoomContainer = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  overflow: 'hidden',
});

const CameraGuide = styled('div')({
  position: 'absolute',
  'z-index': 1,
  boxSizing: 'border-box',
  top: 0,
  left: 0,
  bottom: 0,
  padding: '0 20px 0 35px',
  width: '260px',
  background: 'linear-gradient(to right, rgba(0,0,0,0.8), rgba(0,0,0,0.0))',
  '& .guide-title': {
    paddingTop: '15px',
    color: '#fff',
  },
  '& .guide-label': {
    paddingTop: '15px',
    color: '#fff',
    lineHeight: '100%',
  },
  '& svg': {
    marginTop: '20px',
    width: '80px',
  },
});

const CameraActions = styled('div')({
  display: 'none',
  position: 'absolute',
  'z-index': '9',
  //background: 'rgba(33, 200, 50, 0.3)',
  top: 0,
  width: '100%',
  height: '100%',
  '& #take-photo': {
    display: 'block',
    position: 'absolute',
    top: '50%',
    right: '2.5rem',
    transform: 'translateY(-50%)',
    width: '80px',
    height: '80px',
    borderRadius: '50px',
    background: 'rgba(143, 9, 249, 0.8)',
    border: 'solid 3px rgba(0,0,0,0.7)',
    boxShadow: '0 0 0 7px rgba(255,255,255,0.7) ',
  },
  '& #retake-photo': {},

  '& .skip-key-photo': {
    position: 'absolute',
    top: '10px',
    right: '10px',
    padding: '1rem 2rem',
    borderRadius: '40px',
    color: '#150F1A',
    background: '#fff',
  },
});
const PostCaptureActions = styled('div')({
  display: 'none',
  position: 'absolute',
  bottom: 0,
  width: '100%',
  padding: '4rem 2rem 2rem',
  textAlign: 'center',
  background: 'linear-gradient(rgba(0,0,0,0.0), rgba(0,0,0,0.8))',
  '& button': {
    padding: '1.2rem 2rem',
    color: '#8F09F9',
    borderRadius: '40px',
  },
  '& .retake-photo': {
    color: '#ffffff',
    marginRight: '1rem',
  },
  '& .accept-photo': {
    background: '#ffffff',
    color: '#150F1A',
  },
});

const PreferredBrowsers = styled('div')({
  display: 'none',
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  overflow: 'scroll',
  'z-index': '20',
  padding: '1rem 1.5rem 5rem',
  background: '#ffffff',
  '& h4': {
    marginBottom: '1rem',
  },
  '& h5': {
    marginTop: '1.5rem',
    marginBottom: '.5rem',
  },
  '& p': {
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
  },
  '& button': {
    marginTop: '2rem',
    padding: '.9rem 3rem 1rem',
    borderRadius: '40px',
  },
  '& ul': {
    marginTop: '0',
  },
});

const CameraPermissionsRequired = styled('div')({
  display: 'none',
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  overflow: 'scroll',
  'z-index': '20',
  padding: '1rem 1.5rem 5rem',
  background: '#ffffff',
  '& h4': {
    marginBottom: '1rem',
  },
  '& h5': {
    marginTop: '1.5rem',
    marginBottom: '.5rem',
  },
  '& p': {
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
  },
  '& button': {
    marginTop: '2rem',
    padding: '.9rem 3rem 1rem',
    borderRadius: '40px',
  },
});

const VisualInstructions = styled('div')({
  position: 'absolute',
  top: '50%',
  margin: '0 15px 0 15px',
  transform: 'translateY(-50%)',
  display: 'flex',
  flexWrap: 'wrap',
  'z-index': '10',
  background: '#ffffff',
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: '0 0 80px rgba(0,0,0,0.5)',
  '& .instruction-item': {
    boxSizing: 'border-box',
    width: '50%',
  },
  '& .instruction-item img': {
    width: 'calc(100% - 6px)',
    margin: '4px 4px 0 2px',
    borderRadius: '4px',
  },
  '& .instruction-item:first-child img': {
    margin: '4px 2px 0 4px',
  },
  '& h5': {
    fontSize: '1.1rem',
    color: '#777777',
    textAlign: 'center',
  },
  '& h4': {
    position: 'absolute',
    margin: '3px 0 0 10px',
    color: '#ffffff',
  },
  '& button': {
    padding: '.9rem 3rem 1rem',
    borderRadius: '40px',
  },
  '& button:disabled': {
    padding: '.9rem 3rem 1rem',
    color: '#777777',
    borderRadius: '40px',
    background: '#ECEFF6',
  },
  '& .cta': {
    width: '100%',
    textAlign: 'center',
    padding: '1rem',
  },
});

const PhotoUnderReviewMsg = styled('div')({
  display: 'none',
  position: 'absolute',
  boxSizing: 'border-box',
  top: '0',
  height: '100%',
  width: '100%',
  padding: '1.5rem 1.5rem 1.5rem 3rem',
  'z-index': '200',
  background: 'rgba(0,0,0,0.8)',
  '& h4': {
    fontSize: '3.2rem',
    color: '#ffffff',
  },
  '& p': {
    marginTop: '1rem',
    fontSize: '1.7rem',
    lineHeight: '2rem',
    color: '#ffffff',
  },
  '& .note': {
    fontStyle: 'italic',
    color: '#FFCA40',
  },
});

const CameraNotifications = styled('div')({
  position: 'absolute',
  display: 'none',
  top: '0',
  height: '100%',
  width: '100%',
  'z-index': '10',
});

const CameraMessage = styled('div')({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '100%',
  display: 'none',
  color: '#ffffff',
  textAlign: 'left',
  padding: '2rem 2rem 2rem 2rem',
  background: 'rgba(0,0,0,0.8)',
  '&#tap-screen': {
    height: '100%',
    textAlign: 'center',
    background: '#000000',
  },
  '&#tap-screen .content': {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '100%',
    textAlign: 'center',
  },
  '& h4': {
    fontSize: '2.4rem',
    color: '#FFFFFF',
  },
  '& p': {
    color: '#ffffff',
    fontSize: '1.5rem',
    lineHeight: '1.8rem',
    marginTop: '1rem',
  },
  '& button': {
    padding: '1rem 2rem',
    borderRadius: '40px',
  },
  '& .button-container': {
    marginTop: '2rem',
  },
});

const CameraWarning = styled('div')({
  position: 'absolute',
  bottom: '0',
  width: '100%',
  display: 'none',
  color: '#ffffff',
  textAlign: 'left',
  padding: '5rem 2rem 2rem 3rem',
  background: 'linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0))',
  '& h4': {
    fontSize: '3.2rem',
    color: '#FFCA40',
  },
  '& p': {
    color: '#ffffff',
    fontSize: '1.7rem',
    lineHeight: '2rem',
    marginTop: '1rem',
  },
  '& button': {
    padding: '1rem 2rem',
    borderRadius: '40px',
  },
  '& .button-container': {
    marginTop: '2rem',
  },
  '& .retake-photo': {
    background: '#ffffff',
    color: '#150F1A',
    marginRight: '1rem',
  },
  '& .accept-photo': {
    color: '#150F1A',
  },
});

const CameraError = styled('div')({
  position: 'absolute',
  boxSizing: 'border-box',
  bottom: '0',
  height: '100%',
  width: '100%',
  display: 'none',
  color: '#ffffff',
  textAlign: 'left',
  padding: '5rem 2rem 2rem 3rem',
  borderLeft: 'solid 4rem #F01067',
  background: '#000000',
  '& h4': {
    fontSize: '3.2rem',
    color: '#FFFFFF',
  },
  '& p': {
    color: '#ffffff',
    fontSize: '1.7rem',
    lineHeight: '2rem',
    marginTop: '1rem',
  },
  '& button': {
    padding: '1rem 2rem',
    borderRadius: '40px',
  },
  '& .button-container': {
    marginTop: '2rem',
  },
  '& .reload-camera': {
    background: '#ffffff',
    color: '#150F1A',
  },
});

const Hidden = styled('div')({
  position: 'absolute',
  left: '0',
  height: 0,
  width: 0,
  overflow: 'hidden',
});

const IconTransmitting = styled('div')({
  marginTop: '2rem',
  height: '6px',
  background: '#ffffff',
  borderRadius: '10px',
});

function degreesToRadians(degrees: number) {
  return (degrees * Math.PI) / 180;
}

function distanceInMilesBetweenEarthCoordinates(lat1: number, lon1: number, lat2: number, lon2: number) {
  var earthRadiusMiles = 3958.8;

  var dLat = degreesToRadians(lat2 - lat1);
  var dLon = degreesToRadians(lon2 - lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadiusMiles * c;
}

const cameraReady = () => {
  let actionsEl = document.getElementById('camera-actions');
  if (actionsEl) {
    actionsEl.style.display = 'block';
  }

  // Possibly fix problems with the camera loading improperely and causing stretching
  let camera = document.getElementById('camera');
  if (camera && window.video.stream) {
    let tracks = window.video.stream.getVideoTracks();
    if (camera.offsetWidth > camera.offsetHeight) {
      let constraints = {
        width: { ideal: camera.offsetWidth * 2 * 0.7 },
        height: { ideal: camera.offsetHeight * 2 * 0.7 },
      };
      tracks[0].applyConstraints(constraints);
    } else {
      let constraints = {
        width: { ideal: camera.offsetHeight * 2 * 0.7 },
        height: { ideal: camera.offsetWidth * 2 * 0.7 },
      };
      tracks[0].applyConstraints(constraints);
    }
  }

  hideCameraNotifications();
};

const cameraNotReady = () => {
  let actionsEl = document.getElementById('camera-actions');
  if (actionsEl) {
    actionsEl.style.display = 'none';
  }

  if (/Android/.test(navigator.userAgent)) {
    showCameraNotification('camera-loading');
  } else {
    showCameraNotification('tap-screen');
  }
};

const hideCameraNotifications = () => {
  let notificationsEl = document.getElementById('camera-notifications');
  if (notificationsEl) {
    notificationsEl.style.display = 'none';
    let notices = notificationsEl.getElementsByClassName('notice');
    for (var i = 0; i < notices.length; i++) {
      let n = notices[i] as HTMLDivElement;
      n.style.display = 'none';
    }
  }
};

const showCameraNotification = (id: any, msg?: any) => {
  let notificationsEl = document.getElementById('camera-notifications');
  if (notificationsEl) {
    notificationsEl.style.display = 'block';
    let n = document.getElementById(id);
    if (n) {
      n.style.display = 'block';
      if (msg) {
        let messageEl = n.getElementsByClassName('notification-body');
        if (messageEl) {
          messageEl[0].innerHTML = msg;
        }
      }
    }
  }
};

function getCookie(name: any) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    let p = parts.pop();
    if (p) {
      return p.split(';').shift();
    }
  }
}

function getP(name: any) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');

  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);

  if (results == null) {
    return '';
  }

  return decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function startCamera(cameraWidth: number, cameraHeight: number, videoEl: any, cameraPermissionsRequiredEl: any) {
  showCameraNotification('camera-loading');
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    console.log('calling get user media');
    navigator.mediaDevices
      .getUserMedia({
        video: {
          width: { ideal: cameraWidth * 0.7 },
          height: { ideal: cameraHeight * 0.7 },
          facingMode: {
            exact: 'environment',
          },
        },
        audio: true,
      })
      .then(function(stream) {
        console.log('called get user media successfully');
        cameraPermissionsRequiredEl.current.style.display = 'none';
        if (videoEl.current) {
          window.video.stream = stream;
          videoEl.current.srcObject = stream;
          videoEl.current.onloadedmetadata = function(e: any) {
            // Does not load when the screen in black.
            console.log('video meta data loaded ', e);
            videoEl.current.play();
            cameraReady();
          };

          console.log('video is ', videoEl.current);
        } else {
          console.log('just initialize the camera');
        }
      })
      .catch(function(error: any) {
        console.log('Could not use the camera ', error);
        cameraPermissionsRequiredEl.current.style.display = 'block';
        trackEvent(getP('id') as string, {
          key: 'failedCameraPermissions',
          value: JSON.stringify({
            user: getCookie('user'),
            user_agent: navigator.userAgent,
            screen_width: window.screen.width,
            screen_height: window.screen.height,
            error: error,
            app_version: window.appVersion,
          }),
        });
      });
  } else {
    console.log('getUserMedia API is not available');
    trackEvent(getP('id') as string, {
      key: 'getUserMediaAPIUnavailable',
      value: JSON.stringify({
        user: getCookie('user'),
        user_agent: navigator.userAgent,
        screen_width: window.screen.width,
        screen_height: window.screen.height,
        app_version: window.appVersion,
      }),
    });

    let notice = document.getElementById('preferred-browsers');
    if (notice) {
      notice.style.display = 'block';
    }
  }
}

function ExampleImages() {
  if (window.job.experienceType == 'four_corners' || window.job.experienceType == 'four_corners_and_loaded_vehicle') {
    return (
      <>
        <div className="instruction-item">
          <Typography variant="h4">Take this</Typography>
          <img src={imageAcceptableCornerPhotoExample} alt="Acceptable photo example" />
          <Typography variant="h5">Entire car visible</Typography>
        </div>
        <div className="instruction-item">
          <Typography variant="h4">...Never this</Typography>
          <img src={imageNotAcceptableCornerPhotoExample} alt="Unacceptable photo example" />
          <Typography variant="h5">Partial picture</Typography>
        </div>
      </>
    );
  } else if (window.job.experienceType == '360_video' || window.job.experienceType == '360_video_and_loaded_vehicle') {
    return (
      <>
        <div className="instruction-item">
          <Typography variant="h4">Take this</Typography>
          <img src={imageAcceptablePhotoExample} alt="Acceptable photo example" />
          <Typography variant="h5">Entire car visible</Typography>
        </div>
        <div className="instruction-item">
          <Typography variant="h4">...Never this</Typography>
          <img src={imageNotAcceptableCornerPhotoExample} alt="Unacceptable photo example" />
          <Typography variant="h5">Partial picture</Typography>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="instruction-item">
          <Typography variant="h4">Take this</Typography>
          <img src={imageFlatbedAcceptablePhotoExample} alt="Acceptable photo example" />
          <Typography variant="h5">Vehicle on flatbed</Typography>
        </div>
        <div className="instruction-item">
          <Typography variant="h4">...Never this</Typography>
          <img src={imageFlatbedNotAcceptablePhotoExample} alt="Unacceptable photo example" />
          <Typography variant="h5">Incorrect tow truck type</Typography>
        </div>
      </>
    );
  }
}

interface CameraProps {
  step: number;
  setStep: (step: Steps) => void;
}

const Camera = ({ step, setStep }: CameraProps) => {
  //const { error, setError } = useAppState();
  const [cookies, setCookie] = useCookies(['user']);
  const swivelEl = useRef<HTMLDivElement>(null);
  const cameraPageMainEl = useRef<HTMLDivElement>(null);
  const cameraEl = useRef<HTMLDivElement>(null);
  const videoEl = useRef<HTMLVideoElement>(null);
  const capturedPhotoCanvasEl = useRef<HTMLCanvasElement>(null);
  const takePhotoButton = useRef<HTMLButtonElement>(null);
  const postCaptureActionsEl = useRef<HTMLDivElement>(null);
  const cameraActionsEl = useRef<HTMLDivElement>(null);
  const landscape = useOrientation() === 'LANDSCAPE';
  const classes = useStyles();
  //const [actionsRight, setActionsRight] = useState('0');
  const [forcedOrientation, setForcedOrientation] = useState(false);
  const motionSwitchToCamera = useRef(false);
  //const switchingToCamera = useRef(false);
  //const switchingToCameraTimeout = useRef<any>(null);
  const capturedPhoto = useRef(false);
  const capturedPhotoURI = useRef('');
  const showedInstructions = useRef(false);
  const showedInstructionsTimer = useRef<any>(null);
  //const videoStream = useRef<any>(null);
  const context = useRef<any>(null);
  const forcedOrientationContext = useRef<any>(null);
  const thinkingModalEl = useRef<HTMLDivElement>(null);
  const visualInstructionsEl = useRef<HTMLDivElement>(null);
  const resolution = useRef(2);
  const tickTO = useRef<any>(null);
  const [tickCount, setTickCount] = useState(0);
  const [visualInstructionsCountdown, setVisualInstructionsCountdown] = useState(5);
  const closeVisualInstructionsEl = useRef<HTMLButtonElement>(null);
  const [closeVisualInstructionsDisabled, setCloseVisualInstructionsDisabled] = useState(true);
  const preferredBrowsersEl = useRef<HTMLDivElement>(null);
  const cameraPermissionsRequiredEl = useRef<HTMLDivElement>(null);
  const cameraWidth = useRef(0);
  const cameraHeight = useRef(0);
  let needMotionPermissions = true;
  const cameraNotificationsEl = useRef<HTMLDivElement>(null);
  const vehicleNotVisibleWarningEl = useRef<HTMLDivElement>(null);
  const photoWorkspaceEl = useRef<HTMLCanvasElement>(null);
  const photoUnderReviewMsgEl = useRef<HTMLDivElement>(null);
  const lastSubmittedImageDocID = useRef(null);
  const inactiveTimeout = useRef<any>(null);
  const retakePhotoBeforeSubmitCount = useRef<number>(0);
  const rejectedPhotoCount = useRef<number>(0);
  const verifyPhotoTries = 36;
  let isAndroid = false;
  const ancillaryPhotoTO = useRef<any>(null);
  const ancillaryContext = useRef<any>(null);
  const ancillaryCapturedPhotoCanvasEl = useRef<HTMLCanvasElement>(null);
  const [takingCornerPhoto, setTakingCornerPhoto] = useState(1);
  const [takingKeyPhoto, setTakingKeyPhoto] = useState(false);
  const [showPhotoGuide, setShowPhotoGuide] = useState(false);

  if (/Android/.test(navigator.userAgent)) {
    needMotionPermissions = false;
    isAndroid = true;
  }

  useEffect(() => {
    let body = document.getElementsByTagName('body');
    if (body) {
      body[0].style.backgroundColor = '#000000';
    }
  });

  useEffect(() => {
    tickTO.current = setTimeout(function() {
      if (cameraEl.current) {
        var topScroll = document.documentElement.scrollTop || document.body.scrollTop;
        var leftScroll = document.documentElement.scrollLeft || document.body.scrollLeft;

        if (landscape && document.body.scrollHeight - cameraEl.current.offsetHeight < topScroll + 100) {
          console.log('scrolled far enough down');
          if (closeVisualInstructionsEl.current) {
            var countdown = visualInstructionsCountdown - 1;
            setVisualInstructionsCountdown(countdown);
            if (countdown < 1) {
              setCloseVisualInstructionsDisabled(false);
              closeVisualInstructionsEl.current.innerHTML = 'Continue';
              return; // this will also stop the constant watching of position
            } else {
              closeVisualInstructionsEl.current.innerHTML = 'Continue in ' + countdown;
            }
          }
        }

        if (forcedOrientation && !landscape && leftScroll <= 100) {
          console.log('scrolled far enough down');
          if (closeVisualInstructionsEl.current) {
            var countdown = visualInstructionsCountdown - 1;
            setVisualInstructionsCountdown(countdown);
            if (countdown < 1) {
              setCloseVisualInstructionsDisabled(false);
              closeVisualInstructionsEl.current.innerHTML = 'Continue';
              return; // this will also stop the constant watching of position
            } else {
              closeVisualInstructionsEl.current.innerHTML = 'Continue in ' + countdown;
            }
          }
        }
      }
      setTickCount(tickCount + 1);
    }, 1000);
  }, [tickCount]);

  useEffect(() => {
    if (
      swivelEl.current &&
      cameraActionsEl.current &&
      cameraEl.current &&
      visualInstructionsEl.current &&
      cameraNotificationsEl.current &&
      thinkingModalEl.current &&
      photoUnderReviewMsgEl.current
    ) {
      if (landscape) {
        swivelEl.current.style.width = 'auto';
        cameraActionsEl.current.style.width = '100%';
        cameraActionsEl.current.style.height = '100%';
        visualInstructionsEl.current.style.width = 'calc(100% - 30px)';
        cameraNotificationsEl.current.style.width = '100%';
        cameraNotificationsEl.current.style.height = '100%';
        thinkingModalEl.current.style.width = '100%';
        thinkingModalEl.current.style.height = '100%';
        photoUnderReviewMsgEl.current.style.width = '100%';
        photoUnderReviewMsgEl.current.style.height = '100%';
      } else {
        if (cameraPageMainEl.current) {
          swivelEl.current.style.width = cameraPageMainEl.current.offsetHeight + 'px';
          cameraActionsEl.current.style.width = cameraEl.current.offsetHeight + 'px';
          cameraActionsEl.current.style.height = cameraEl.current.offsetWidth + 'px';
          visualInstructionsEl.current.style.width = cameraEl.current.offsetHeight - 30 + 'px';
          cameraNotificationsEl.current.style.width = cameraEl.current.offsetHeight + 'px';
          cameraNotificationsEl.current.style.height = cameraEl.current.offsetWidth + 'px';
          thinkingModalEl.current.style.width = cameraEl.current.offsetHeight + 'px';
          thinkingModalEl.current.style.height = cameraEl.current.offsetWidth + 'px';
          photoUnderReviewMsgEl.current.style.width = cameraEl.current.offsetHeight + 'px';
          photoUnderReviewMsgEl.current.style.height = cameraEl.current.offsetWidth + 'px';
        }
      }
    }
  }, [landscape, forcedOrientation]);

  // Scroll to the top of the instuctions when we show the camera
  useEffect(() => {
    if (!showedInstructions.current && (landscape || forcedOrientation)) {
      window.scrollTo(document.body.scrollWidth, 0);
      clearTimeout(showedInstructionsTimer.current);
      showedInstructionsTimer.current = setTimeout(function() {
        showedInstructions.current = true;
      }, 3000);
    } else {
      clearTimeout(showedInstructionsTimer.current);
    }
  }, [landscape, forcedOrientation]);

  useEffect(() => {
    let timeout: any;
    if (window.DeviceMotionEvent) {
      //window.addEventListener('devicemotion', motion, false);
      window.addEventListener(
        'devicemotion',
        function(event: any) {
          // We don't want this to mess with the behavior if the device is already in landscape
          // We also don't want to accidently wipe out the captured photo since the device motion is more
          // sensitive than the device native screen rotation
          if (window.innerWidth < window.innerHeight && !capturedPhoto.current) {
            //console.log('motion function triggered');
            //if (Math.abs(event.accelerationIncludingGravity.x) > 6.5) {
            if (
              Math.abs(event.accelerationIncludingGravity.x) + Math.abs(event.accelerationIncludingGravity.z) > 12.5 ||
              Math.abs(event.accelerationIncludingGravity.x) > 7.4
            ) {
              //console.log('preventAutoScroll is ' + preventAutoScroll);
              if (!motionSwitchToCamera.current) {
                clearTimeout(timeout);
                timeout = setTimeout(function() {
                  setForcedOrientation(true);
                }, 500);
                motionSwitchToCamera.current = true;
              }
            } else {
              if (motionSwitchToCamera.current) {
                clearTimeout(timeout);
                timeout = setTimeout(function() {
                  setForcedOrientation(false);
                }, 500);
                motionSwitchToCamera.current = false;
              }
            }
          } else if (capturedPhoto.current && window.innerWidth < window.innerHeight) {
            clearTimeout(timeout);
            setForcedOrientation(true);
            motionSwitchToCamera.current = true;
          } else {
            clearTimeout(timeout);
            setForcedOrientation(false);
            motionSwitchToCamera.current = false;
          }
        },
        false
      );
    } else {
      console.log('DeviceMotionEvent is not supported');
      trackEvent(getSecret() as string, {
        key: 'motionNotSupported',
        value: JSON.stringify({
          user: cookies.user,
          userAgent: navigator.userAgent,
          app_version: window.appVersion,
        }),
      });
    }
  }, []);

  const stopMediaTracks = (wait?: number | 0) => {
    if (window.video.stream) {
      var tracks = window.video.stream.getTracks();
      if (tracks.length) {
        console.log('stop tracks in 6 seconds');
        clearTimeout(inactiveTimeout.current);
        inactiveTimeout.current = setTimeout(() => {
          cameraNotReady();
          console.log('stop tracks', tracks);
          for (var i = 0; i < tracks.length; i++) {
            tracks[i].stop();
            console.log('stop track ', tracks[i]);
          }
          console.log('tracks should be disabled', tracks);
        }, wait);
      }
    }
  };

  const reconnectCamera = () => {
    let activeVideo = false;
    if (window.video.stream) {
      let tracks = window.video.stream.getTracks();
      for (var i = 0; i < tracks.length; i++) {
        console.log('checking for live track ', tracks[i]);
        if (tracks[i].kind === 'video' && tracks[i].readyState === 'live') {
          activeVideo = true;
        }
      }
    }

    if (!activeVideo) {
      startCamera(cameraWidth.current, cameraHeight.current, videoEl, cameraPermissionsRequiredEl);
    } else {
      console.log('live video track already exists');
    }
  };

  useEffect(() => {
    window.onfocus = () => {
      console.log('app is in focus');
      console.log('cancel the stopping of tracks');
      trackEvent(getSecret() as string, {
        key: 'appInFocus',
        value: JSON.stringify({
          user: cookies.user,
          app_version: window.appVersion,
        }),
      });
      clearTimeout(inactiveTimeout.current);
      reconnectCamera();
    };

    window.onblur = () => {
      console.log('app is in background');
      trackEvent(getSecret() as string, {
        key: 'appInBackground',
        value: JSON.stringify({
          user: cookies.user,
          app_version: window.appVersion,
        }),
      });
      stopMediaTracks(); // Trying this with no delay
    };
  }, []);

  const motionPermissionsIntro = () => {
    console.log('show the motion permissions intro');
    trackEvent(getSecret() as string, {
      key: 'showMotionPermissions',
      value: JSON.stringify({
        user: cookies.user,
        app_version: window.appVersion,
      }),
    });
    let deviceMotionAskIntro = document.getElementById('device-motion-ask-intro');
    if (deviceMotionAskIntro) {
      deviceMotionAskIntro.style.display = 'block';
    }
  };

  const askForMotionPermissions = () => {
    let deviceMotionAskIntro = document.getElementById('device-motion-ask-intro');
    if (deviceMotionAskIntro) {
      deviceMotionAskIntro.style.display = 'none';
    }
    trackEvent(getSecret() as string, {
      key: 'askMotionPermissions',
      value: JSON.stringify({
        user: cookies.user,
        app_version: window.appVersion,
      }),
    });

    if (window.DeviceMotionEvent && typeof DeviceMotionEvent.requestPermission === 'function') {
      console.log('I guess this is iOS 13+');
      DeviceMotionEvent.requestPermission()
        .then(permissionState => {
          console.log('Permission state is ', permissionState);
          if (permissionState === 'granted') {
            trackEvent(getSecret() as string, {
              key: 'motionGranted',
              value: JSON.stringify({
                user: cookies.user,
                app_version: window.appVersion,
              }),
            });
            //window.addEventListener("devicemotion", motion, false);
          } else if (permissionState === 'denied') {
            console.log('device motion permissions are denied');
            trackEvent(getSecret() as string, {
              key: 'motionDenied',
              value: JSON.stringify({
                user: cookies.user,
                app_version: window.appVersion,
              }),
            });

            let deniedDeviceMotion = document.getElementById('denied-device-motion-notice');
            if (deniedDeviceMotion) {
              deniedDeviceMotion.style.display = 'block';
            }
          }
        })
        .catch(console.error);
    } else {
      console.log("Non iOS13 can we track? Shouldn't need to click the won't rotate");
      // handle regular non iOS 13+ devices
    }
  };

  // For requesting initial permissions only.
  useEffect(() => {
    // Get access to the camera
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      console.log('initialize the camera');
      startCamera(window.innerWidth, window.innerHeight, videoEl, cameraPermissionsRequiredEl);
    }
  }, []);

  useEffect(() => {
    // The trigger points for a phones natural switch to landscape and our own
    // orientation detection for devices with locked screen rotation are different
    // and can cause restarting of the camera so we need to only do it
    if (
      cameraEl.current &&
      capturedPhotoCanvasEl.current &&
      videoEl.current &&
      ancillaryCapturedPhotoCanvasEl.current
    ) {
      console.log('Camera IS visible, and video stream ', window.video.stream);
      console.log('video width:' + videoEl.current.offsetWidth + ' height:' + videoEl.current.offsetHeight);

      capturedPhotoCanvasEl.current.setAttribute('width', '' + cameraEl.current.offsetWidth * resolution.current);
      capturedPhotoCanvasEl.current.setAttribute('height', '' + cameraEl.current.offsetHeight * resolution.current);

      ancillaryCapturedPhotoCanvasEl.current.setAttribute(
        'width',
        '' + cameraEl.current.offsetWidth * resolution.current
      );
      ancillaryCapturedPhotoCanvasEl.current.setAttribute(
        'height',
        '' + cameraEl.current.offsetHeight * resolution.current
      );

      context.current = capturedPhotoCanvasEl.current.getContext('2d');
      context.current.save();
      console.log(
        'canvas width:' +
          capturedPhotoCanvasEl.current.offsetWidth +
          ' height:' +
          capturedPhotoCanvasEl.current.offsetHeight
      );

      ancillaryContext.current = ancillaryCapturedPhotoCanvasEl.current.getContext('2d');

      cameraWidth.current = cameraEl.current.offsetWidth * resolution.current;
      cameraHeight.current = cameraEl.current.offsetHeight * resolution.current;

      if (forcedOrientation) {
        cameraWidth.current = cameraEl.current.offsetHeight * resolution.current;
        cameraHeight.current = cameraEl.current.offsetWidth * resolution.current;
        console.log(
          'Forced Orientation contraints camera width: ' + cameraWidth.current + ' and height: ' + cameraHeight.current
        );
      }

      if (landscape) {
        cameraWidth.current = cameraEl.current.offsetWidth * resolution.current;
        cameraHeight.current = cameraEl.current.offsetHeight * resolution.current;
        console.log(
          'Landscape setting contraints width: ' + cameraWidth.current + ' and height: ' + cameraHeight.current
        );
      }

      if (landscape || forcedOrientation) {
        if (window.video.stream) {
          let tracks = window.video.stream.getVideoTracks();
          let constraints = {
            width: { ideal: cameraWidth.current * 0.7 },
            height: { ideal: cameraHeight.current * 0.7 },
          };
          tracks[0].applyConstraints(constraints);
        }
      }
    } else {
      console.log('Camera not visible, but video stream ', window.video.stream);
    }
  }, [landscape, forcedOrientation]);

  const closeVisualInstructions = () => {
    if (visualInstructionsEl.current) {
      visualInstructionsEl.current.style.display = 'none';
      // Show picture guide
      setShowPhotoGuide(true);
    }
  };

  function thinkingOn(text?: any) {
    let textEl = document.getElementById('thinking-text');
    if (text) {
      if (textEl) {
        textEl.style.display = 'block';
        textEl.innerHTML = text;
      }
    } else {
      if (textEl) {
        textEl.style.display = 'none';
        textEl.innerHTML = '';
      }
    }

    if (thinkingModalEl.current) {
      thinkingModalEl.current.style.display = 'block';
    }
  }

  function thinkingOff() {
    let textEl = document.getElementById('thinking-text');
    if (textEl) {
      textEl.style.display = 'none';
      textEl.innerHTML = '';
    }

    if (thinkingModalEl.current) {
      thinkingModalEl.current.style.display = 'none';
    }
  }

  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function scanForVehicle(video: HTMLVideoElement, frameCount: number) {
    if (window.detection.net) {
      let photos: any[] = [];
      for (let i = 0; i <= frameCount; i++) {
        let found;
        if (forcedOrientation && photoWorkspaceEl.current && cameraEl.current) {
          photoWorkspaceEl.current.setAttribute('width', '' + cameraEl.current.offsetHeight);
          photoWorkspaceEl.current.setAttribute('height', '' + cameraEl.current.offsetWidth);
          console.log(
            'trying to draw the force orientation context',
            cameraEl.current.offsetWidth,
            cameraEl.current.offsetHeight
          );
          forcedOrientationContext.current = photoWorkspaceEl.current.getContext('2d');
          //forcedOrientationContext.current.clearRect(0, 0, photoWorkspaceEl.current?.width, photoWorkspaceEl.current?.height);
          forcedOrientationContext.current.rotate((-90 * Math.PI) / 180);
          //forcedOrientationContext.current.translate( cameraEl.current.offsetWidth/2, cameraEl.current.offsetHeight/2);
          //forcedOrientationContext.current.translate( -368, -207);
          forcedOrientationContext.current.translate(-cameraEl.current.offsetWidth, 0);
          //forcedOrientationContext.current.translate( -(cameraEl.current.offsetWidth/2), -(cameraEl.current.offsetHeight/2));
          forcedOrientationContext.current.drawImage(video, 0, 0, video.offsetWidth, video.offsetHeight);

          found = await scanIteration(photoWorkspaceEl.current, frameCount);
        } else {
          found = await scanIteration(video, frameCount);
        }

        if (found) {
          photos.push(found);
          break;
        }
        await sleep(200);
      }

      return photos;
    } else {
      throw new Error('Net not loaded so cannot do detection.');
    }
  }

  async function scanIteration(source: any, count: number) {
    let detection = await detectVehicle(source, window.detection.net);
    if (detection.vehicleVisible) {
      // Need to rotate the image before we draw it
      if (forcedOrientation && cameraEl.current) {
        context.current.rotate((90 * Math.PI) / 180);
        context.current.translate(0, -(cameraEl.current.offsetHeight * 1.135)); // Based on resolution and buffer (hidden area)
      }
      context.current.drawImage(
        source,
        0,
        0,
        source.offsetWidth * resolution.current,
        source.offsetHeight * resolution.current
      );

      // Prevents repeat photos from constantly rotating the canvas
      context.current.restore();
      context.current.save();

      if (capturedPhotoCanvasEl.current) {
        return { photo: capturedPhotoCanvasEl.current.toDataURL('image/jpeg'), score: detection.score };
      } else {
        return;
      }
    } else {
      return;
    }
  }

  function captureAndSendPhoto(lat: any, lng: any, distance: any, onScene: any, userStatedOnScene: any) {
    hideCameraNotifications();

    let canvasContainer = document.getElementById('canvas-container');

    // keep the canvas hidden until we decide which image to use
    if (canvasContainer) {
      canvasContainer.style.zIndex = '-1';
    }

    // capture the initial frame at the time they click to take the photo
    if (videoEl.current) {
      context.current.drawImage(
        videoEl.current,
        0,
        0,
        videoEl.current.offsetWidth * resolution.current,
        videoEl.current.offsetHeight * resolution.current
      );
    }

    // TODO HACK Excluding this for now since we will be testing out another form of vehicle review
    if (!isAndroid && false) {
      thinkingOn('Hold still');
      scanForVehicle(videoEl.current as HTMLVideoElement, 10)
        .then(photos => {
          console.log('photos from the scan', photos);
          let photoImage = new Image();
          let detectedVehicle = false;
          let detectedVehicleScore;

          if (photos.length) {
            let highestScore = 0;
            let highestScoreIndex = 0;
            for (let i = 0; i < photos.length; i++) {
              if (photos[i].score > highestScore) {
                highestScore = photos[i].score;
                highestScoreIndex = i;
              }
            }
            detectedVehicle = true;
            detectedVehicleScore = highestScore;
            // Replace the captured image with the highest detection score
            console.log('Using the image with the highest detection score', photos[highestScoreIndex].photo);
            photoImage.src = photos[highestScoreIndex].photo;
            context.current.drawImage(
              photoImage,
              0,
              0,
              photoImage.offsetWidth * resolution.current,
              photoImage.offsetHeight * resolution.current
            );
          } else {
            console.log('No viable vehicle photo found');
            showCameraNotification('vehicle-not-visible-warning');

            trackEvent(getSecret() as string, {
              key: 'vehicleDetectionFail',
              value: JSON.stringify({
                user: cookies.user,
                app_version: window.appVersion,
              }),
            });
          }

          if (canvasContainer) {
            canvasContainer.style.zIndex = '1';
          }
          thinkingOff();
          sendPhoto(lat, lng, distance, onScene, userStatedOnScene, detectedVehicle, detectedVehicleScore);
        })
        .catch(error => {
          console.log('Error doing vehicle detection: ', error);
          trackEvent(getSecret() as string, {
            key: 'vehicleDetectionError',
            value: JSON.stringify({
              user: cookies.user,
              reason: error,
              app_version: window.appVersion,
            }),
          });

          if (canvasContainer) {
            canvasContainer.style.zIndex = '1';
          }
          thinkingOff();
          sendPhoto(lat, lng, distance, onScene, userStatedOnScene);
        });
    } else {
      if (canvasContainer) {
        canvasContainer.style.zIndex = '1';
      }

      if (capturedPhotoCanvasEl.current) {
        let imageString = capturedPhotoCanvasEl.current.toDataURL('image/jpeg');
        if (imageString.indexOf('A'.repeat(800)) > -1) {
          showCameraNotification('photo-capture-error');
          trackEvent(getSecret() as string, {
            key: 'black-screen-error',
            value: JSON.stringify({
              user: cookies.user,
              reason: 'A repeated',
              app_version: window.appVersion,
            }),
          });
        } else if (imageString.indexOf('AooooAKKKKACiiig'.repeat(30)) > -1) {
          showCameraNotification('photo-capture-error');
          trackEvent(getSecret() as string, {
            key: 'black-screen-error',
            value: JSON.stringify({
              user: cookies.user,
              reason: 'AooooAKKKKACiiig repeated',
              app_version: window.appVersion,
            }),
          });
        } else {
          sendPhoto(lat, lng, distance, onScene, userStatedOnScene);
        }
      } else {
        sendPhoto(lat, lng, distance, onScene, userStatedOnScene);
      }
    }
  }

  function sendPhoto(
    lat: any,
    lng: any,
    distance: any,
    onScene: any,
    userStatedOnScene: any,
    vehicleDetected?: boolean,
    detectionScore?: any
  ) {
    if (capturedPhotoCanvasEl.current && postCaptureActionsEl.current && takePhotoButton.current) {
      var image = new Image();

      capturedPhotoURI.current = capturedPhotoCanvasEl.current.toDataURL('image/jpeg');
      image.src = capturedPhotoURI.current;
      console.log('image is ', image);

      takePhotoButton.current.style.display = 'none';
      postCaptureActionsEl.current.style.display = 'block';
      capturedPhoto.current = true;

      if (capturedPhotoURI.current) {
        thinkingOn();
        const formData = new FormData();
        formData.append('secret', getSecret() || '');
        if (lat) {
          console.log('adding the lat to photo:', lat);
          formData.append('lat', lat);
        }
        if (lng) {
          console.log('adding the lng to photo:', lng);
          formData.append('lng', lng);
        }
        if (distance) {
          console.log('adding the dist to photo:', distance);
          formData.append('dist', distance);
        }
        if (onScene) {
          console.log('adding the on_scene to photo:', onScene);
          formData.append('on_scene', onScene);
        }
        if (userStatedOnScene) {
          console.log('adding the user_stated_on_scene to photo:', userStatedOnScene);
          formData.append('user_stated_on_scene', userStatedOnScene);
        }
        if (window.job.experienceType === 'loaded_vehicle') {
          formData.append('category', 'loaded_vehicle_photos');
        }

        formData.append('data_uri', encodeURI(capturedPhotoURI.current));
        console.log('photo form data:', formData);

        fetch(window.HONK_ENV.baseUrl + 'accident_videos/damage_photo', {
          method: 'POST',
          mode: 'cors',
          body: formData,
        })
          .then(response => response.json())
          .then(data => {
            thinkingOff();
            lastSubmittedImageDocID.current = data.document_id;
            if (vehicleDetected) {
              trackEvent(getSecret() as string, {
                key: 'vehicleDetected',
                value: JSON.stringify({
                  user: cookies.user,
                  document_id: data.document_id,
                  score: detectionScore,
                  app_version: window.appVersion,
                }),
              });
            }
          })
          .catch(error => {
            thinkingOff();
            console.log('save photo error:', error);
            trackEvent(getSecret() as string, {
              key: 'error',
              value: JSON.stringify({
                user: cookies.user,
                reason: 'unable to save photo',
                app_version: window.appVersion,
              }),
            });
          });
      } else {
        trackEvent(getSecret() as string, {
          key: 'error',
          value: JSON.stringify({
            user: cookies.user,
            reason: 'no photo captured to send',
            app_version: window.appVersion,
          }),
        });
        console.log('ERROR: No photo captured to send');
      }
    }
  }

  const captureAncillaryPhoto = (additionalCaptures: number) => {
    // capture the initial frame at the time they click to take the photo
    if (videoEl.current) {
      ancillaryContext.current.drawImage(
        videoEl.current,
        0,
        0,
        videoEl.current.offsetWidth * resolution.current,
        videoEl.current.offsetHeight * resolution.current
      );
      let x = (videoEl.current.offsetWidth * resolution.current) / 2;
      let y = (videoEl.current.offsetHeight * resolution.current) / 2;
      let lineX = (videoEl.current.offsetWidth * resolution.current) / 2 - 40;
      let lineY = (videoEl.current.offsetHeight * resolution.current) / 2 - 40;
      ancillaryContext.current.beginPath();
      ancillaryContext.current.moveTo(x - lineX, y - lineY);
      ancillaryContext.current.lineTo(x + lineX, y + lineY);

      ancillaryContext.current.moveTo(x + lineX, y - lineY);
      ancillaryContext.current.lineTo(x - lineX, y + lineY);
      ancillaryContext.current.strokeStyle = 'rgba(255,0,0,0.8)';
      ancillaryContext.current.lineWidth = 80;
      ancillaryContext.current.stroke();
    }

    if (ancillaryCapturedPhotoCanvasEl.current) {
      let photoDataURL = ancillaryCapturedPhotoCanvasEl.current.toDataURL('image/jpeg');
      var image = new Image();
      image.src = photoDataURL;
      console.log('ancillary image data ', image);

      if (photoDataURL) {
        const formData = new FormData();
        formData.append('secret', getSecret() || '');
        formData.append('data_uri', encodeURI(photoDataURL));
        formData.append('ancillary_photo', '1');

        fetch(window.HONK_ENV.baseUrl + 'accident_videos/damage_photo', {
          method: 'POST',
          mode: 'cors',
          body: formData,
        })
          .then(response => response.json())
          .then(data => {
            if (additionalCaptures > 2) {
              clearTimeout(ancillaryPhotoTO.current);
              ancillaryPhotoTO.current = setTimeout(() => {
                captureAncillaryPhoto(additionalCaptures - 1);
              }, 3000);
            }
            /*
            trackEvent(getSecret() as string, {
              key: 'ancillaryPhoto',
              value: JSON.stringify({
                user: cookies.user,
                document_id: data.document_id,
                ancillary_for_document_id: lastSubmittedImageDocID.current,
                app_version: window.appVersion,
              }),
            });
            */
          })
          .catch(error => {
            console.log('ancillary photo error:', error);
            trackEvent(getSecret() as string, {
              key: 'error',
              value: JSON.stringify({
                user: cookies.user,
                reason: 'unable to save ancillary photo',
                ancillary_for_document_id: lastSubmittedImageDocID.current,
                app_version: window.appVersion,
              }),
            });
          });
      } else {
        trackEvent(getSecret() as string, {
          key: 'error',
          value: JSON.stringify({
            user: cookies.user,
            reason: 'no ancillary photo captured to send',
            app_version: window.appVersion,
          }),
        });
        console.log('ERROR: No ancillary photo captured to send');
      }
    }
  };

  // Trigger photo take
  const capturePhoto = () => {
    let lat;
    let lng;
    let distance;

    trackEvent(getSecret() as string, {
      key: 'pressed-capture-photo',
      value: JSON.stringify({
        user: cookies.user,

        on_scene: window.driver.onScene,
        lat: window.driver.lat,
        long: window.driver.long,
        distance_to_disablement: window.driver.distanceFromJob,
        app_version: window.appVersion,
      }),
    });

    // Sometimes we have no GEO information
    if ('geolocation' in navigator) {
      console.log('Photo: GEO Available'); // DEBUG
      navigator.geolocation.getCurrentPosition(
        function(position) {
          // DEBUG
          console.log('Photo: Latitude is :', position.coords.latitude);
          console.log('Photo: Longitude is :', position.coords.longitude);

          let distanceFromJob = distanceInMilesBetweenEarthCoordinates(
            position.coords.latitude,
            position.coords.longitude,
            window.job.lat,
            window.job.long
          );
          console.log('Photo: distance in Miles: ' + distanceFromJob);
          // Update the driver position information so we have the latest
          window.driver.lat = position.coords.latitude;
          window.driver.long = position.coords.longitude;
          window.driver.distanceFromJob = distanceFromJob;
          lat = position.coords.latitude;
          lng = position.coords.longitude;
          distance = distanceFromJob;

          // We'll consider a mile or less close enough to be considered at the job location
          if (distanceFromJob <= 1) {
            window.driver.onScene = 'true';
            console.log('Photo: driver scene data is ', window.driver);
            captureAndSendPhoto(lat, lng, distance, 'true', null);
          } else {
            window.driver.onScene = 'false';
            console.log('Photo: driver scene data is ', window.driver);
            captureAndSendPhoto(lat, lng, distance, 'false', null);
          }

          trackEvent(getSecret() as string, {
            key: 'photo_location',
            value: JSON.stringify({
              user: cookies.user,
              on_scene: window.driver.onScene,
              lat: window.driver.lat,
              long: window.driver.long,
              distance_to_disablement: window.driver.distanceFromJob,
              app_version: window.appVersion,
            }),
          });
        },
        function(error) {
          // Error codes
          // 1 - Denied permissions
          console.log('Photo: the error getting geo: ', error);
          captureAndSendPhoto(null, null, null, null, window.driver.userStatedOnScene);
        }
      );
    } else {
      console.log('Photo: GEO Not Available. Skip Check');
      captureAndSendPhoto(null, null, null, null, window.driver.userStatedOnScene);
    }
  };

  const retakePhotoBeforeSubmit = () => {
    retakePhotoBeforeSubmitCount.current = retakePhotoBeforeSubmitCount.current + 1;

    trackEvent(getSecret() as string, {
      key: 'retakePhotoBeforeSubmit',
      value: JSON.stringify({
        user: cookies.user,
        last_submitted_document_id: lastSubmittedImageDocID.current,
        retake_count: retakePhotoBeforeSubmitCount.current,
        app_version: window.appVersion,
      }),
    });
    switchToCamera();
  };

  const switchToCamera = () => {
    // keep the canvas hidden until we decide which image to use
    let canvasContainer = document.getElementById('canvas-container');
    if (canvasContainer) {
      canvasContainer.style.zIndex = '-1';
    }

    hideCameraNotifications();

    if (postCaptureActionsEl.current && takePhotoButton.current) {
      context.current.clearRect(0, 0, capturedPhotoCanvasEl.current?.width, capturedPhotoCanvasEl.current?.height);
      takePhotoButton.current.style.display = 'block';
      postCaptureActionsEl.current.style.display = 'none';
      capturedPhoto.current = false;
    }

    setShowPhotoGuide(true);
  };

  const nextPhoto = () => {
    trackEvent(getSecret() as string, {
      key: 'pressedNextPhoto',
      value: JSON.stringify({
        user: cookies.user,
        app_version: window.appVersion,
      }),
    });
    retakePhotoBeforeSubmitCount.current = 0; // Reset this count when they mark they are done with this photo
    verifyPhoto(getSecret(), lastSubmittedImageDocID.current, verifyPhotoTries, 5); // should be close to 120s
  };

  const doneWithPhotos = () => {
    trackEvent(getSecret() as string, {
      key: 'pressedSubmitPhoto',
      value: JSON.stringify({
        user: cookies.user,
        app_version: window.appVersion,
      }),
    });
    retakePhotoBeforeSubmitCount.current = 0; // Reset this count when they mark they are done
    verifyPhoto(getSecret(), lastSubmittedImageDocID.current, verifyPhotoTries, 5); // should be close to 120s
    /* TODO Remove this test eventually
    setTimeout(() => {
      captureAncillaryPhoto(15);
    }, 1500);
    */
  };

  const exitingCamera = () => {
    window.onblur = () => {}; // cleanup since we are no longer in this section
    window.onfocus = () => {}; // cleanup since we are no longer in this section
    stopMediaTracks();
  };

  const switchToNextPhoto = () => {
    setTakingCornerPhoto(takingCornerPhoto + 1);
    switchToCamera();
  };

  const skipKeyPhoto = () => {
    trackEvent(getSecret() as string, {
      key: 'skippedKeyPhoto',
      value: JSON.stringify({
        user: cookies.user,
        app_version: window.appVersion,
      }),
    });
    exitingCamera();
    setStep(Steps.videoInstructionsStep);
  };

  const doneWithKeyPhoto = () => {
    trackEvent(getSecret() as string, {
      key: 'submittedKeyPhoto',
      value: JSON.stringify({
        user: cookies.user,
        app_version: window.appVersion,
      }),
    });
    exitingCamera();
    setStep(Steps.videoInstructionsStep);
  };

  const verifyPhoto = (secret: any, document_id: any, triesLeft?: any, errorTriesLeft?: any) => {
    if (photoUnderReviewMsgEl.current && postCaptureActionsEl.current) {
      photoUnderReviewMsgEl.current.style.display = 'block';
      postCaptureActionsEl.current.style.display = 'none';
    }

    // Hide the guide while we process so it doesn't clutter the screen for other messages
    setShowPhotoGuide(false);

    let tryCount = verifyPhotoTries - triesLeft + 1;

    fetch(window.HONK_ENV.baseUrl + 'accident_videos/realtime_score?secret=' + secret + '&document_id=' + document_id)
      .then(response => response.json())
      .then(data => {
        if (data.scored && data.acceptable) {
          console.log('photo accepted');
          clearTimeout(ancillaryPhotoTO.current); // Don't capture any more ancillary photos when we have a response to the user
          trackEvent(getSecret() as string, {
            key: 'acceptedPhoto',
            value: JSON.stringify({
              user: cookies.user,
              document_id: document_id,
              reason: data.reason,
              automation: data.automation,
              passthru: data.passthru,
              destinationChanged: data.destination_changed,
              try: tryCount,
              prior_rejections: rejectedPhotoCount.current,
              app_version: window.appVersion,
            }),
          });

          if (window.job.experienceType == 'four_corners') {
            if (photoUnderReviewMsgEl.current) {
              photoUnderReviewMsgEl.current.style.display = 'none';
            }

            if (takingCornerPhoto < 4) {
              switchToNextPhoto();
            } else {
              setTakingKeyPhoto(true);
              switchToNextPhoto();
            }
          } else if (window.job.experienceType === 'loaded_vehicle') {
            exitingCamera();
            setStep(Steps.finishedRecordingStep);
          } else {
            // Notify the user in the event of a tow destination change
            if (data.destination_changed) {
              console.log('destination changed');
              fetch(window.HONK_ENV.baseUrl + 'accident_videos/job?secret=' + getSecret())
                .then(response => response.json())
                .then(
                  data => {
                    window.job.destinationAddress = data.destination_address;
                    window.job.destinationName = data.destination_business_name;
                    window.job.destinationPhone = data.destination_phone_number;
                    console.log('new address', window.job.destinationAddress);
                    setStep(Steps.destinationChangeStep);
                  },
                  error => {
                    insp([
                      'tagSession',
                      {
                        id: getSecret(),
                        error: error,
                      },
                    ]);

                    console.log('Job details error:', error);

                    // If we can't get the job data then we'll just let them through
                    exitingCamera();
                    setStep(Steps.videoInstructionsStep);
                  }
                );
            } else {
              // Image is acceptable so let them through
              exitingCamera();
              setStep(Steps.videoInstructionsStep);
            }
          }
        } else if (data.scored && !data.acceptable) {
          console.log('photo rejected');
          clearTimeout(ancillaryPhotoTO.current); // Don't capture any more ancillary photos when we have a response to the user
          trackEvent(getSecret() as string, {
            key: 'rejectedPhoto',
            value: JSON.stringify({
              user: cookies.user,
              document_id: document_id,
              reason: data.reason,
              try: tryCount,
              prior_rejections: rejectedPhotoCount.current,
              app_version: window.appVersion,
            }),
          });

          rejectedPhotoCount.current = rejectedPhotoCount.current + 1; // Tally
          // Image not acceptable, so let them know
          showRetakePhotoMsg(data.reason);
        } else {
          if (triesLeft > 1) {
            setTimeout(() => {
              console.log('check on the photo score again');
              verifyPhoto(secret, document_id, triesLeft - 1, errorTriesLeft);
            }, 3000);
          } else {
            if (window.job.experienceType == 'four_corners') {
              if (photoUnderReviewMsgEl.current) {
                photoUnderReviewMsgEl.current.style.display = 'none';
              }

              if (takingCornerPhoto < 4) {
                switchToNextPhoto();
              } else {
                setTakingKeyPhoto(true);
                switchToNextPhoto();
              }
            } else if (window.job.experienceType === 'loaded_vehicle') {
              exitingCamera();
              setStep(Steps.finishedRecordingStep);
            } else {
              // Image is acceptable so let them through
              exitingCamera();
              setStep(Steps.videoInstructionsStep);
            }
          }
        }
      })
      .catch(error => {
        console.log('Error checking the status of scoring: ', error);
        trackEvent(getSecret() as string, {
          key: 'error',
          value: JSON.stringify({
            user: cookies.user,
            reason: 'failed to score image: ' + error,
            app_version: window.appVersion,
          }),
        });
        if (triesLeft > 1 && errorTriesLeft > 1) {
          setTimeout(() => {
            verifyPhoto(secret, document_id, triesLeft - 1, errorTriesLeft - 1);
          }, 3000);
        } else {
          if (window.job.experienceType == 'four_corners') {
            if (photoUnderReviewMsgEl.current) {
              photoUnderReviewMsgEl.current.style.display = 'none';
            }

            if (takingCornerPhoto < 4) {
              switchToNextPhoto();
            } else {
              setTakingKeyPhoto(true);
              switchToNextPhoto();
            }
          } else if (window.job.experienceType === 'loaded_vehicle') {
            exitingCamera();
            setStep(Steps.finishedRecordingStep);
          } else {
            // Image is acceptable so let them through
            exitingCamera();
            setStep(Steps.videoInstructionsStep);
          }
        }
      });
  };

  const showRetakePhotoMsg = (msg?: any) => {
    if (photoUnderReviewMsgEl.current && postCaptureActionsEl.current) {
      photoUnderReviewMsgEl.current.style.display = 'none';
      postCaptureActionsEl.current.style.display = 'none';
    }
    showCameraNotification('photo-not-accepted', msg);
  };

  const reAskCameraPermissions = () => {
    startCamera(cameraWidth.current, cameraHeight.current, videoEl, cameraPermissionsRequiredEl);
  };

  const reloadCamera = () => {
    window.location.reload();
    // TODO should just reload this camera section so the user doesn't have to go through all the steps
  };

  var orientationNoticeTitle = '';
  var cameraFirstPageTitle = '';
  var cameraFirstPageInstructions = '';
  var reviewingTitle = '';
  var reviewingInstructions = '';

  switch (window.job.experienceType) {
    case 'four_corners':
      orientationNoticeTitle = 'Pre-tow photos';
      cameraFirstPageTitle = 'Corners & key photos';
      cameraFirstPageInstructions =
        'Scroll down and take photos of the vehicle from each corner with the entire vehicle in frame. Aferwards, take a picture of the car keys if they are going with the vehicle.';
      reviewingTitle = 'Reviewing photo...';
      reviewingInstructions =
        'Please wait while our system reviews this photo for required content (full vehicle view).';
      break;
    case 'loaded_vehicle':
      orientationNoticeTitle = 'Loaded Vehicle photo';
      cameraFirstPageTitle = 'Loaded Vehicle photo';
      cameraFirstPageInstructions =
        'Scroll down and take photos of the vehicle loaded in the tow truck. The entire vehicle and tow truck must be in frame.';
      reviewingTitle = 'Reviewing photo...';
      reviewingInstructions =
        'Please wait while our system reviews this photo for required content (vehicle loaded onto a tow truck).';
      break;
    case '360_video_and_loaded_vehicle':
      orientationNoticeTitle = 'Accident Photo';
      cameraFirstPageTitle = 'Main damage photo';
      cameraFirstPageInstructions =
        'Scroll down and take a photo of the vehicle damage with the entire vehicle in frame. Taking this photo at an angle is fine if it is required to capture the entire vehicle with the damage.';
      reviewingTitle = 'Reviewing photo...';
      reviewingInstructions =
        'Please wait while our system reviews this damage photo for required content (damage & full vehicle view).';
      break;
    case 'four_corners_and_loaded_vehicle':
      orientationNoticeTitle = 'Pre-tow photos';
      cameraFirstPageTitle = 'Corners & key photos';
      cameraFirstPageInstructions =
        'Scroll down and take photos of the vehicle from each corner with the entire vehicle in frame. Aferwards, take a picture of the car keys if they are going with the vehicle.';
      reviewingTitle = 'Reviewing photo...';
      reviewingInstructions =
        'Please wait while our system reviews this photo for required content (full vehicle view).';
      break;
    default:
      // 360_video
      orientationNoticeTitle = 'Accident Photo';
      cameraFirstPageTitle = 'Main damage photo';
      cameraFirstPageInstructions =
        'Scroll down and take a photo of the vehicle damage with the entire vehicle in frame. Taking this photo at an angle is fine if it is required to capture the entire vehicle with the damage.';
      reviewingTitle = 'Reviewing photo...';
      reviewingInstructions =
        'Please wait while our system reviews this damage photo for required content (damage & full vehicle view).';
  }

  //<Detection source={videoEl} />
  //<canvas id="detection-canvas"></canvas>
  return (
    <>
      <CameraPermissionsRequired id="camera-permissions-required" ref={cameraPermissionsRequiredEl}>
        <Typography variant="h4">Camera access required</Typography>
        <Typography variant="body1">
          This application will not work without camera access. If you denied access, press the{' '}
          <strong>Retry access</strong> button and allow it when your device asks your permission. If you were not asked
          to allow access by your device, you will need to change your settings.
        </Typography>
        <Typography variant="h5">iPhone</Typography>
        <Typography variant="body1">
          Enable camera access for your browser in <strong>Settings > Privacy > Camera</strong>.
        </Typography>
        <Typography variant="body1">
          <br />
          <strong>Note:</strong> If you have difficulty doing this, please switch to the Safari browser.
        </Typography>
        <Typography variant="h5">Android</Typography>
        <Typography variant="body1">
          If you are using an Android device, enable camera access in <strong>Settings > Site Settings > Camera</strong>
          .
        </Typography>
        <Button onClick={reAskCameraPermissions} variant="contained" color="primary">
          Retry access
        </Button>
      </CameraPermissionsRequired>
      <PreferredBrowsers id="preferred-browsers" ref={preferredBrowsersEl}>
        <Typography variant="h4">Unsupported browser</Typography>
        <Typography variant="body1">
          This application will not work with your current browser. Please use a browser from the compatibility list
          below.
        </Typography>
        <Typography variant="h5">iPhone</Typography>
        <Typography variant="body1">
          <ul>
            <li>Safari</li>
          </ul>
        </Typography>
        <Typography variant="h5">Android</Typography>
        <Typography variant="body1">
          <ul>
            <li>Chrome</li>
            <li>Firefox</li>
          </ul>
        </Typography>
      </PreferredBrowsers>
      <DeniedDeviceMotion id="denied-device-motion-notice">
        <Typography variant="h4">App will not work without motion permissions</Typography>
        <Typography variant="body1">
          This app will not work without access to your devices motion. To use this app you must either close this tab
          and re-open the app in another, or restart your browser.
        </Typography>
      </DeniedDeviceMotion>
      <DeviceMotionAskIntro id="device-motion-ask-intro">
        <Typography variant="h4">Motion access required</Typography>
        <Typography variant="body1">
          This app uses your devices motion to assist with taking this photo. Tap "Allow" when asked to allow access.
        </Typography>
        <Button onClick={askForMotionPermissions} variant="contained" color="primary">
          Request Motion Access
        </Button>
      </DeviceMotionAskIntro>
      {!landscape && !forcedOrientation && (
        <OrientationNotice>
          <div className="orientation-notice-inner">
            <Typography variant="h4" className="orientation-title">
              {orientationNoticeTitle}
            </Typography>
            <IconRotatePhone className="icon-rotate-phone" />
            <Typography variant="body1" className="note-text">
              Turn your phone sideways and point the camera toward the vehicle.
            </Typography>
            {needMotionPermissions ? (
              <div className="wont-rotate">
                If your screen will not rotate, tap <Link onClick={motionPermissionsIntro}>here.</Link>
              </div>
            ) : (
              <div className="wont-rotate">If your screen does not rotate, please enable screen rotation.</div>
            )}
          </div>
        </OrientationNotice>
      )}
      <Main id="camera-page-main" ref={cameraPageMainEl} className={landscape ? '' : classes.notInLandscape}>
        <div className="swivel-container">
          <div id="swivel" className="swivel" ref={swivelEl}>
            <section className={classes.instructions}>
              <header className={classes.instructionsHeader}>
                <>
                  <Typography variant="h4" className={classes.instructionsTitle}>
                    {cameraFirstPageTitle}
                  </Typography>
                  <Grid container justify="center">
                    <Grid item className={classes.instructionsIconContainer}>
                      <IconArrowDown />
                    </Grid>
                    <Grid item className={classes.instructionsTextContainer}>
                      <Typography variant="body1" className={classes.instructionsText}>
                        {cameraFirstPageInstructions}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              </header>
            </section>
          </div>
        </div>
        <CameraBody id="camera" ref={cameraEl}>
          <CameraNotifications id="camera-notifications" ref={cameraNotificationsEl}>
            <CameraMessage className="notice" id="camera-loading">
              <Typography variant="h4">Camera loading...</Typography>
              <Typography className="notification-body" variant="body1">
                If it does not load in the next few seconds, another browser tab may be using your camera already. If
                so, close that tab and reload this application. If you are unable to find another tab using your camera,
                try closing your browser completely and restarting it. If that doesn't work, try restarting your phone.
              </Typography>
            </CameraMessage>
            <CameraMessage className="notice" id="tap-screen">
              <div className="content">
                <Typography variant="h4">Tap the screen to refresh the camera</Typography>
                <Typography className="notification-body" variant="body1">
                  If that does not work, try reloading this application.
                </Typography>
              </div>
            </CameraMessage>
            <CameraWarning className="notice" id="photo-not-accepted">
              <Typography variant="h4">Photo rejected</Typography>
              <Typography className="notification-body" variant="body1">
                All of the vehicle must be visible when taking the damage photo.
              </Typography>
              <div className="button-container">
                <Button onClick={switchToCamera} className="retake-photo">
                  Retake photo
                </Button>
              </div>
            </CameraWarning>
            <CameraWarning className="notice" id="vehicle-not-visible-warning">
              <Typography variant="h4">Can't see all of the vehicle</Typography>
              <Typography variant="body1">
                Backing up or changing your angle to the vehicle and take another photo.
              </Typography>
              <div className="button-container">
                <Button onClick={switchToCamera} className="retake-photo">
                  Take another photo
                </Button>
                <Button onClick={doneWithPhotos} className="accept-photo">
                  Done with photos
                </Button>
              </div>
            </CameraWarning>
            <CameraError className="notice" id="photo-capture-error">
              <Typography variant="h4">Capture Error</Typography>
              <Typography className="notification-body" variant="body1">
                There is a problem capturing the photo. Please reload the application or restart your browser.
              </Typography>
              <div className="button-container">
                <Button onClick={reloadCamera} className="reload-camera">
                  Reload App
                </Button>
              </div>
            </CameraError>
          </CameraNotifications>
          <ZoomContainer>
            <video id="video" ref={videoEl} muted playsInline autoPlay></video>
          </ZoomContainer>
          <Hidden>
            <canvas id="photo-work-space" ref={photoWorkspaceEl}></canvas>
            <canvas id="ancillary-captured-picture-canvas" ref={ancillaryCapturedPhotoCanvasEl}></canvas>
          </Hidden>
          <div id="canvas-container">
            <canvas id="detection-canvasXXXX"></canvas>
            <canvas id="captured-picture-canvas" ref={capturedPhotoCanvasEl}></canvas>
          </div>
          {window.job.experienceType == 'four_corners' && showPhotoGuide && (
            <>
              {takingCornerPhoto == 1 && (
                <CameraGuide id="camera-guide-1">
                  <Typography variant="h4" className="guide-title">
                    Photo 1 of 4
                  </Typography>
                  <Typography variant="h5" className="guide-label">
                    Driver front <br />
                    corner
                  </Typography>
                  <IconDriverFrontCorner />
                </CameraGuide>
              )}
              {takingCornerPhoto == 2 && (
                <CameraGuide id="camera-guide-2">
                  <Typography variant="h4" className="guide-title">
                    Photo 2 of 4
                  </Typography>
                  <Typography variant="h5" className="guide-label">
                    Passenger front <br />
                    corner
                  </Typography>
                  <IconPassengerFrontCorner />
                </CameraGuide>
              )}
              {takingCornerPhoto == 3 && (
                <CameraGuide id="camera-guide-3">
                  <Typography variant="h4" className="guide-title">
                    Photo 3 of 4
                  </Typography>
                  <Typography variant="h5" className="guide-label">
                    Passenger back <br />
                    corner
                  </Typography>
                  <IconPassengerBackCorner />
                </CameraGuide>
              )}
              {takingCornerPhoto == 4 && (
                <CameraGuide id="camera-guide-4">
                  <Typography variant="h4" className="guide-title">
                    Photo 4 of 4
                  </Typography>
                  <Typography variant="h5" className="guide-label">
                    Driver back <br />
                    corner
                  </Typography>
                  <IconDriverBackCorner />
                </CameraGuide>
              )}
              {takingKeyPhoto && (
                <CameraGuide id="camera-guide-4">
                  <Typography variant="h4" className="guide-title">
                    Picture of <br />
                    car keys
                  </Typography>
                </CameraGuide>
              )}
            </>
          )}
          <CameraActions id="camera-actions" ref={cameraActionsEl}>
            <Button onClick={capturePhoto} id="take-photo" ref={takePhotoButton}>
              &nbsp;
            </Button>
            {takingKeyPhoto && (
              <Button onClick={skipKeyPhoto} className="skip-key-photo">
                Skip this
              </Button>
            )}
            {window.job.experienceType == 'four_corners' ? (
              <PostCaptureActions ref={postCaptureActionsEl}>
                <Button onClick={retakePhotoBeforeSubmit} className="retake-photo">
                  Retake photo
                </Button>
                {takingKeyPhoto ? (
                  <Button onClick={doneWithKeyPhoto} className="accept-photo">
                    Done
                  </Button>
                ) : (
                  <Button onClick={nextPhoto} className="accept-photo">
                    Next photo
                  </Button>
                )}
              </PostCaptureActions>
            ) : (
              <PostCaptureActions ref={postCaptureActionsEl}>
                <Button onClick={retakePhotoBeforeSubmit} className="retake-photo">
                  Retake photo
                </Button>
                <Button onClick={doneWithPhotos} className="accept-photo">
                  Submit photo
                </Button>
              </PostCaptureActions>
            )}
          </CameraActions>
          <VisualInstructions id="visual-directions" ref={visualInstructionsEl}>
            <ExampleImages />
            <div className="cta">
              <Button
                onClick={closeVisualInstructions}
                ref={closeVisualInstructionsEl}
                variant="contained"
                color="primary"
                disabled={closeVisualInstructionsDisabled}
              >
                Continue in 5
              </Button>
            </div>
          </VisualInstructions>

          <PhotoUnderReviewMsg id="photo-under-review" ref={photoUnderReviewMsgEl}>
            <Typography variant="h4">{reviewingTitle}</Typography>
            <Typography variant="body1">{reviewingInstructions}</Typography>
            <Typography variant="body1" className="note">
              This can take up to a minute
            </Typography>
            <IconTransmitting className="transmitting" />
          </PhotoUnderReviewMsg>

          <ThinkingContainer id="thinking" ref={thinkingModalEl}>
            <Typography id="thinking-text" variant="h3"></Typography>
            <Thinking className={classes.thinking}>
              <IconProcessing className="rotating" />
            </Thinking>
          </ThinkingContainer>
        </CameraBody>
      </Main>
    </>
  );
};

export default Camera;
