import React, { useEffect, useState } from 'react';
import { Typography, Button, Grid, makeStyles, Link, Theme, styled } from '@material-ui/core';
import { Steps } from '../../../App';
import { useLocation } from 'react-router-dom';
import ImageEntireVehicle from './ImageEntireVehicle';
import ImageZoomedInDamage from './ImageZoomedInDamage';
import trackEvent from '../../../utils/trackEvent/trackEvent';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    textAlign: 'center',
    paddingTop: '5px',
  },
  buttonLink: {
    textDecoration: 'none',
  },
  notTheDriverTitle: {
    marginTop: '2.6rem',
  },
  subText: {
    marginTop: '10px',
    marginBottom: '20px',
    lineHeight: '1.3rem',
  },
}));

const Main = styled('main')(({ theme }: { theme: Theme }) => ({}));

const DamageAreaInstructions = styled('div')({
  background: '#ffffff',
  '& .container': {
    padding: '1rem 1.5rem',
  },
  '& .do-not-container': {
    padding: '1rem 1.5rem',
  },
  '& h4': {
    paddingBottom: '0',
  },
  '& h4.do': {
    marginTop: '2rem',
    color: '#00AD7C',
  },
  '& h4.do:before': {
    content: "'\\A'",
    position: 'absolute',
    marginTop: '-5px',
    width: '15px',
    height: '5px',
    background: '#00AD7C',
  },
  '& h4.do-not': {
    marginTop: '1rem',
    color: '#F01067',
  },
  '& h4.do-not:before': {
    content: "'\\A'",
    position: 'absolute',
    marginTop: '-5px',
    width: '15px',
    height: '5px',
    background: '#F01067',
  },
  '& button': {
    display: 'block',
    width: '90%',
    boxSizing: 'border-box',
    margin: '0 auto',
    padding: '1rem 2.5rem 1rem',
    borderRadius: '3rem',
  },
  '& .open-camera-button': {
    marginTop: '2rem',
  },
});

const InstructionImageContainer = styled('div')({
  marginTop: '1rem',
  '& svg': {
    width: '100%',
    height: 'auto',
  },
});

const Header = styled('header')(() => ({
  background: '#150F1A',
  textAlign: 'center',
  padding: '1.5rem 2rem',
  borderRadius: '0 0 4rem 4rem',
  '& h4': {
    color: '#ffffff',
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface CameraProps {
  step: number;
  setStep: (step: Steps) => void;
}

const DamageAreaInstructionsScreen = ({ step, setStep }: CameraProps) => {
  const classes = useStyles();
  let query = useQuery();
  const [cookies, setCookie] = useCookies(['user']);
  let messageDelim = '&';

  if (/Android/.test(navigator.userAgent)) {
    messageDelim = '?';
  }

  useEffect(() => {
    // Incase a previous screen had scrolled
    window.scrollTo(0, 0);
    trackEvent(query.get('id') as string, {
      key: 'show-damage-photo-instructions',
      value: JSON.stringify({
        user: cookies.user,
        app_version: window.appVersion,
      }),
    });
  }, []);

  const acknowledgeDamageAreaInstructions = () => {
    trackEvent(query.get('id') as string, {
      key: 'clicked-acknowleged-damage-instructions',
      value: JSON.stringify({
        user: cookies.user,
        app_version: window.appVersion,
      }),
    });

    setStep(Steps.cameraStep);
  };

  return (
    <>
      <Header>
        <Typography variant="h4">Camera Access Required</Typography>
      </Header>
      <DamageAreaInstructions>
        <div className="container">
          <Typography variant="body1">
            This app will not work without camera access. Granting it is required to complete this step.
          </Typography>
          <Button
            onClick={acknowledgeDamageAreaInstructions}
            variant="contained"
            color="primary"
            className="open-camera-button"
          >
            Allow camera access
          </Button>
        </div>
      </DamageAreaInstructions>
    </>
  );
};

export default DamageAreaInstructionsScreen;
