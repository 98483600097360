import React, { useState, useEffect, useRef } from 'react';
//import { makeStyles } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import NotRecordingScreens from './components/NotRecordingScreens/NotRecordingScreens';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';
import Rollbar from './state/settings/rollbar';
import insp, { install } from 'inspectlet-es';
import getSecret from './Secret';

//import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import DamageAreaInstructionsScreen from './components/NotRecordingScreens/DamageAreaInstructionsScreen/DamageAreaInstructionsScreen';
import DestinationChangeScreen from './components/NotRecordingScreens/DestinationChangeScreen/DestinationChangeScreen';
import Camera from './components/Camera/Camera';
import VideoCamera from './components/Camera/VideoCamera';
import { Typography, Button } from '@material-ui/core';
import MapMarker from './icons/MapMarker';
import MapMarkerError from './icons/MapMarkerError';
import MapMarkerNotThere from './icons/MapMarkerNotThere';
import MapMarkerUnknown from './icons/MapMarkerUnknown';
import trackEvent from './utils/trackEvent/trackEvent';
// Temporarily disabled detection import { initVehicleDetection } from './components/Camera/DetectVehicle';
import { TrackStats } from 'twilio-video';
import { useCookies } from 'react-cookie';

declare global {
  interface Window {
    HONK_ENV: any;
    driver: any;
    job: any;
    appState: any;
    yolo: any;
    video: any;
    detection: any;
    appVersion: any;
  }
}

export enum Steps {
  geoIntroStep,
  waitingForGeoStep,
  locationRequiredStep,
  notAtJobLocationStep,
  unableToLocateStep,
  damagePhotoInstructionsStep,
  cameraStep,
  destinationChangeStep,
  videoInstructionsStep,
  videoCameraStep,
  finishedRecordingStep,
}

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
  height: '100%',
});

const WaitingForGeo = styled('div')({
  position: 'fixed',
  top: '0',
  left: '0',
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: '3rem',
  textAlign: 'center',
  width: '100%',
  height: '100%',
  background: '#150F1A',
  color: '#ffffff',
  '& svg': {
    width: '150px',
    marginBottom: '1rem',
  },
  '& h1': {
    fontSize: '1.3rem',
  },
});

const GeoIntro = styled('div')({
  position: 'fixed',
  top: '0',
  left: '0',
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: '0 0 15px 0',
  textAlign: 'center',
  width: '100%',
  height: '100%',
  background: '#150F1A',
  color: '#ffffff',
  overflow: 'scroll',
  '& .intro-container': {
    margin: '0 auto',
    maxWidth: 'calc(100% - 2rem)',
    width: '300px',
  },
  '& svg': {
    width: '150px',
    marginBottom: '1rem',
  },
  '& h1': {
    fontSize: '1.6rem',
  },
  '& p': {
    marginTop: '1.2rem',
    color: '#ffffff',
  },
  '& button': {
    display: 'block',
    background: '#ffffff',
    color: '#8F09F9',
    width: '270px',
    maxWidth: '100%',
    margin: '0 auto',
    padding: '.9rem 1.5rem .9rem',
    borderRadius: '4rem',
  },
  '& ul': {
    maxWidth: '4300px',
    margin: '2rem auto .5rem',
    paddingLeft: '0',
    listStyleType: 'none',
    textAlign: 'left',
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
  },
  '& li': {
    marginTop: '1rem',
  },
});

const MarkerContainer = styled('div')({
  animationDuration: '1500ms',
  animationIterationCount: 'infinite',
});

const LocationNotice = styled('div')({
  background: '#ffffff',
  height: '100%',
  paddingTop: '10rem',
});

const NoticeHeader = styled('header')({
  position: 'fixed',
  boxSizing: 'border-box',
  top: '-300px',
  width: '100%',
  height: 'calc(50% + 300px)',
  padding: '315px 1.5rem 1.5rem',
  '&.important': {
    background: '#FFCA40',
  },
  '&.critical': {
    background: '#ED1768',
    color: '#ffffff',
  },
  '&.critical h1': {
    opacity: '0.7',
  },
  '& h1': {
    fontSize: '1.1rem',
    opacity: '0.6',
    fontWeight: '600',
  },
  '& h2': {
    marginTop: '.3rem',
    fontSize: '2.5rem',
    lineHeight: '2.5rem',
  },
  '&.critical svg': {
    top: '300px',
    right: '20px',
  },
  '&.unknown svg': {
    right: '20px',
  },
  '& svg': {
    position: 'absolute',
    'z-index': -1,
    top: '295px',
    right: '0',
  },
});

const NoticeBody = styled('section')({
  position: 'relative',
  'z-index': '1',
  background: '#ffffff',
  borderRadius: '2.8rem 0 0 0',
  padding: '1.5rem 1.5rem 4rem',
  '& h3': {
    marginTop: '2.7rem',
    fontSize: '2rem',
    lineHeight: '2rem',
    paddingBottom: '.8rem',
  },
  '& h3.critical-text': {
    color: '#ED1768',
  },
  '& p': {
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
  },
  '& button': {
    display: 'block',
    width: '270px',
    margin: '0 auto',
    padding: '.9rem 1.5rem .9rem',
    borderRadius: '4rem',
  },
  '& button.secondary': {
    color: '#8F09F9',
    background: '#ECEFF6',
  },
  '& button.do-this': {
    color: '#ffffff',
    background: '#00AD7C',
  },
  '& .address-item': {
    fontWeight: 'bold',
  },
});

const ButtonContainer = styled('div')({
  paddingTop: '2rem',
  '& a': {
    textDecoration: 'none',
  },
});

function degreesToRadians(degrees: number) {
  return (degrees * Math.PI) / 180;
}

function distanceInMilesBetweenEarthCoordinates(lat1: number, lon1: number, lat2: number, lon2: number) {
  var earthRadiusMiles = 3958.8;

  var dLat = degreesToRadians(lat2 - lat1);
  var dLon = degreesToRadians(lon2 - lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadiusMiles * c;
}

function randomString(length: number) {
  let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

export default function App() {
  install(1888606860);
  const roomState = useRoomState();
  //const classes = useStyles();
  const [cookies, setCookie] = useCookies(['user']);
  const [step, setStep] = useState(Steps.geoIntroStep);
  const [vehicleDescription, setVehicleDescription] = useState('');
  const [photoInstructions, setPhotoInstructions] = useState('');
  const [notOperatorInstructions, setNotOperatorInstructions] = useState('');
  const [requirementInstructions, setRequirementInstructions] = useState('');
  const [offScenePhotoButtonLabel, setOffScenePhotoButtonLabel] = useState('');
  const [textMessageToOperator, setTextMessageToOperator] = useState('');
  const [locationUnknownInstructions, setLocationUnknownInstructions] = useState('');
  const [confirmOnSceneButtonLabel, setConfirmOnSceneButtonLabel] = useState('');

  let messageDelim = '&';
  let isAndroid = false;

  // Initialize once
  if (!window.driver) {
    //alert('test 47');
    console.log('initialize driver scene data');
    window.driver = {};
  }

  if (!window.job) {
    window.job = {};
  }

  if (!window.detection) {
    window.detection = {};
  }

  if (!window.appState) {
    console.log('initialize app state');
    window.appState = {};
  }

  if (!window.appState) {
    console.log('initialize app state');
    window.appState = {};
  }

  if (!window.video) {
    console.log('initialize app video');
    window.video = {};
  }

  if (/Android/.test(navigator.userAgent)) {
    messageDelim = '?';
    isAndroid = true;
  }

  let body = document.getElementsByTagName('body');
  if (body) {
    body[0].style.backgroundColor = '#ffffff';
  }

  useEffect(() => {
    if (cookies.user) {
      console.log('returning user ', cookies.user);
    } else {
      let userUniqueString = randomString(20) + '-' + Date.now();
      console.log('new user string ', userUniqueString);
      setCookie('user', userUniqueString, {
        path: '/',
      });
    }

    reportDeviceInfo();

    /* XXX temp disable detection at this time
    if (!isAndroid) {
      initVehicleDetection().then((net: any) => {
        window.detection.net = net;
      });
    }
    */
  }, []);

  const sendToAnother = () => {
    trackEvent(getSecret() as string, {
      key: 'clicked-send-to-another',
      value: JSON.stringify({
        user: cookies.user,
        app_version: window.appVersion,
      }),
    });
  };

  const toRecordOffSceneVideo = () => {
    trackEvent(getSecret() as string, {
      key: 'clicked-off-scene-photo',
      value: JSON.stringify({
        user: cookies.user,
        app_version: window.appVersion,
      }),
    });

    setStep(Steps.damagePhotoInstructionsStep);
  };

  const userIndicatedOnScene = () => {
    trackEvent(getSecret() as string, {
      key: 'clicked-on-scene-photo',
      value: JSON.stringify({
        user: cookies.user,
        app_version: window.appVersion,
      }),
    });

    window.driver.userStatedOnScene = 'true';

    setStep(Steps.damagePhotoInstructionsStep);
  };

  const userIndicatedOffScene = () => {
    trackEvent(getSecret() as string, {
      key: 'clicked-off-scene-photo',
      value: JSON.stringify({
        user: cookies.user,
        app_version: window.appVersion,
      }),
    });

    window.driver.userStatedOnScene = 'false';
    setStep(Steps.damagePhotoInstructionsStep);
  };

  const reloadApp = () => {
    trackEvent(getSecret() as string, {
      key: 'clicked-reload-app',
      value: JSON.stringify({
        user: cookies.user,
        app_version: window.appVersion,
      }),
    });

    window.location.href = window.location.href;
  };

  const proceedWithGeo = () => {
    trackEvent(getSecret() as string, {
      key: 'clicked-proceed-with-geo',
      value: JSON.stringify({
        user: cookies.user,
        app_version: window.appVersion,
      }),
    });

    getJobDetailsAndLocation();
  };

  const reportDeviceInfo = () => {
    let deviceType = 'unknown';
    if (/Android/.test(navigator.userAgent)) {
      deviceType = 'Android';
    } else if (/iPhone/.test(navigator.userAgent)) {
      deviceType = 'iPhone';
    }

    console.log(
      'device information: ' +
        JSON.stringify({
          device: deviceType,
          userAgent: navigator.userAgent,
          screenWidth: window.screen.width,
          screenHeight: window.screen.height,
          app_version: window.appVersion,
        })
    );
    trackEvent(getSecret() as string, {
      key: 'device',
      value: JSON.stringify({
        user: cookies.user,
        device: deviceType,
        userAgent: navigator.userAgent,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        app_version: window.appVersion,
      }),
    });
  };

  const getJobDetailsAndLocation = () => {
    fetch(window.HONK_ENV.baseUrl + 'accident_videos/job?secret=' + getSecret())
      .then(response => response.json())
      .then(
        data => {
          //console.log('ftrack result:', result);
          //console.log('JDetails:', data); // DEBUG
          insp(['tagSession', data]);

          window.job.lat = data.lat;
          window.job.long = data.long;
          window.job.experienceType = data.experience_type;
          // don't put the secret in a query string parameter; query string parameters do not make it
          // to the messages app due to tracking / security features of iOS
          var url = window.location.protocol + '//' + window.location.host + '/' + getSecret();
          switch (window.job.experienceType) {
            case 'four_corners':
              setPhotoInstructions(
                'Photos and a 360 video with the entire vehicle in frame are required before loading the vehicle for this job.'
              );
              setNotOperatorInstructions(
                'Send this to the operator on-scene so they can take the pictures and video prior to loading the vehicle.'
              );
              setRequirementInstructions(
                'IT IS A REQUIREMENT TO TAKE PHOTOS & A VIDEO PRIOR TO LOADING. Failure to do so may affect the status of your account.'
              );
              setOffScenePhotoButtonLabel('Off-scene photos');
              setTextMessageToOperator(
                'sms:' +
                  messageDelim +
                  'body=Re:%20HONK%20Job%0A' +
                  encodeURI(
                    'Photos and a video with the entire vehicle in frame are required for this job prior to loading the vehicle. '
                  ) +
                  '%C2%A0%0A%0A' +
                  encodeURI('Take the photos and video with the entire vehicle in frame using the link below.') +
                  '%0A' +
                  encodeURI(url + '?handoff=1') +
                  '%0A%0A' +
                  encodeURI('Open or forward this link to the operator arriving on scene for the ') +
                  encodeURI(data.vehicle_description) +
                  '%20tow.%0A%0A' +
                  encodeURI('These photos and video are used to protect you against future damage claims for this job.')
              );
              setLocationUnknownInstructions(
                'We are unable to determine if you are at the pickup location. If you are ready to take photos and a ' +
                  'video of the vehicle, indicate if you are at the pickup location or not. Otherwise, proceed when you ' +
                  'are there. '
              );
              setConfirmOnSceneButtonLabel('At pickup location');

              break;
            case 'loaded_vehicle':
              setPhotoInstructions(
                'Photo with the entire vehicle in frame is required after loading the vehicle for this job.'
              );
              setNotOperatorInstructions(
                'Send this to the operator on-scene so they can take the pictures after loading the vehicle.'
              );
              setRequirementInstructions(
                'IT IS A REQUIREMENT TO TAKE A PHOTO AFTER LOADING. Failure to do so may affect the status of your account.'
              );
              setOffScenePhotoButtonLabel('Off-scene photo');
              setTextMessageToOperator(
                'sms:' +
                  messageDelim +
                  'body=Re:%20HONK%20Job%0A' +
                  encodeURI(
                    'This tow is required to have a photo of the entire vehicle and tow truck in frame after the vehicle has been loaded.'
                  ) +
                  '%C2%A0%0A%0A' +
                  encodeURI('Take the photo with the entire vehicle and flatbed truck in frame using the link below.') +
                  '%0A%0A' +
                  encodeURI('Open or forward this link to the operator arriving on scene for the ') +
                  encodeURI(data.vehicle_description) +
                  '%20tow.%0A%0A' +
                  '%0A' +
                  encodeURI(url + '?handoff=1') +
                  '%0A'
              );
              setLocationUnknownInstructions(
                'We are unable to determine if you are at the pickup location. If you are ready to take a photo ' +
                  'of the vehicle, indicate if you are at the pickup location or not. Otherwise, proceed when you ' +
                  'are there. '
              );
              setConfirmOnSceneButtonLabel('At pickup location');
              break;
            case '360_video_and_loaded_vehicle':
              setPhotoInstructions(
                'Photos & video with the entire vehicle in frame are required both before and after loading the vehicle for this job.'
              );
              setNotOperatorInstructions(
                'Send this to the operator on-scene so they can take the pictures and video prior and after loading the vehicle.'
              );
              setRequirementInstructions(
                'IT IS A REQUIREMENT TO TAKE A PHOTO & VIDEO BEFORE AND AFTER LOADING. Failure to do so may affect the status of your account.'
              );
              setOffScenePhotoButtonLabel('Off-scene photos & video');
              setTextMessageToOperator(
                'sms:' +
                  messageDelim +
                  'body=Re:%20HONK%20Job%0A' +
                  encodeURI(
                    'This vehicle was reported to have pre-existing damage from being involved in an accident. Photos and a video with the entire vehicle in frame are required for this job prior to and after loading the vehicle. '
                  ) +
                  '%C2%A0%0A%0A' +
                  encodeURI('Take the photos and video with the entire vehicle in frame using the link below.') +
                  '%0A' +
                  encodeURI(url + '?handoff=1') +
                  '%0A%0A' +
                  encodeURI('Open or forward this link to the operator arriving on scene for the ') +
                  encodeURI(data.vehicle_description) +
                  '%20tow.%0A%0A' +
                  encodeURI('These photos and video are used to protect you against future damage claims for this job.')
              );
              setConfirmOnSceneButtonLabel('I am on scene');
              setLocationUnknownInstructions(
                'We are unable to determine if you are at the accident scene. If you are ready to take photos and a ' +
                  'video of the vehicle, indicate if you are on scene or not. Otherwise, proceed when you are on scene.'
              );

              break;
            case 'four_corners_and_loaded_vehicle':
              setPhotoInstructions(
                'Photos and a 360 video with the entire vehicle in frame are required before and after loading the vehicle for this job.'
              );
              setNotOperatorInstructions(
                'Send this to the operator on-scene so they can take the pictures and video prior to and after loading the vehicle.'
              );
              setRequirementInstructions(
                'IT IS A REQUIREMENT TO TAKE PHOTOS & A VIDEO PRIOR TO AND AFTER LOADING. Failure to do so may affect the status of your account.'
              );
              setOffScenePhotoButtonLabel('Off-scene photos & video');
              setTextMessageToOperator(
                'sms:' +
                  messageDelim +
                  'body=Re:%20HONK%20Job%0A' +
                  encodeURI(
                    'Photos and a video with the entire vehicle in frame are required for this job prior to and after loading the vehicle. '
                  ) +
                  '%C2%A0%0A%0A' +
                  encodeURI('Take the photos and video with the entire vehicle in frame using the link below.') +
                  '%0A' +
                  encodeURI(url + '?handoff=1') +
                  '%0A%0A' +
                  encodeURI('Open or forward this link to the operator arriving on scene for the ') +
                  encodeURI(data.vehicle_description) +
                  '%20tow.%0A%0A' +
                  encodeURI('These photos and video are used to protect you against future damage claims for this job.')
              );
              setLocationUnknownInstructions(
                'We are unable to determine if you are at the pickup location. If you are ready to take photos ' +
                  'of the vehicle, indicate if you are at the pickup location or not. Otherwise, proceed when you ' +
                  'are there. '
              );
              setConfirmOnSceneButtonLabel('At pickup location');
              break;
            default:
              // 360_video
              setPhotoInstructions(
                'A photo & video with the entire vehicle in frame is required before loading the vehicle for this job.'
              );
              setNotOperatorInstructions(
                'Send this to the operator on-scene so they can take the pictures and video prior to loading the vehicle.'
              );
              setRequirementInstructions(
                'IT IS A REQUIREMENT TO TAKE A PHOTO & VIDEO PRIOR TO LOADING. Failure to do so may affect the status of your account.'
              );
              setOffScenePhotoButtonLabel('Off-scene photo & video');
              setTextMessageToOperator(
                'sms:' +
                  messageDelim +
                  'body=Re:%20HONK%20Job%0A' +
                  encodeURI(
                    'This vehicle was reported to have pre-existing damage from being involved in an accident. Photos and a video with the entire vehicle in frame are required for this job prior to loading the vehicle. '
                  ) +
                  '%C2%A0%0A%0A' +
                  encodeURI('Take the photos and video with the entire vehicle in frame using the link below.') +
                  '%0A' +
                  encodeURI(url + '?handoff=1') +
                  '%0A%0A' +
                  encodeURI('Open or forward this link to the operator arriving on scene for the ') +
                  encodeURI(data.vehicle_description) +
                  '%20tow.%0A%0A' +
                  encodeURI('These photos and video are used to protect you against future damage claims for this job.')
              );
              setConfirmOnSceneButtonLabel('I am on scene');
              setLocationUnknownInstructions(
                'We are unable to determine if you are at the accident scene. If you are ready to take photos and a ' +
                  'video of the vehicle, indicate if you are on scene or not. Otherwise, proceed when you are on scene.'
              );
          }

          setVehicleDescription(data.vehicle_description);

          if ('geolocation' in navigator) {
            console.log('GEO Available'); // DEBUG
            navigator.geolocation.getCurrentPosition(
              function(position) {
                // DEBUG
                console.log('Latitude is :', position.coords.latitude);
                console.log('Longitude is :', position.coords.longitude);

                let distanceFromJob = distanceInMilesBetweenEarthCoordinates(
                  position.coords.latitude,
                  position.coords.longitude,
                  data.lat,
                  data.long
                );
                // HACK
                //distanceFromJob = distanceInMilesBetweenEarthCoordinates(34.222691, -118.616985, data.lat, data.long);
                console.log('distance in Miles: ' + distanceFromJob);

                window.driver.lat = position.coords.latitude;
                window.driver.long = position.coords.longitude;
                window.driver.distanceFromJob = distanceFromJob;

                // We'll consider a mile or less close enough to be considered at the job location
                if (distanceFromJob <= 1) {
                  window.driver.onScene = 'true';
                  console.log('driver scene data is ', window.driver);

                  trackEvent(getSecret() as string, {
                    key: 'geo-near-job-status',
                    value: JSON.stringify({
                      user: cookies.user,
                      distance: distanceFromJob,
                      app_version: window.appVersion,
                    }),
                  });
                  setStep(Steps.damagePhotoInstructionsStep);
                } else {
                  window.driver.onScene = 'false';
                  console.log('driver scene data is ', window.driver);

                  trackEvent(getSecret() as string, {
                    key: 'geo-not-at-job-location',
                    value: JSON.stringify({
                      user: cookies.user,
                      distance: distanceFromJob,
                      app_version: window.appVersion,
                    }),
                  });
                  setStep(Steps.notAtJobLocationStep);
                  var jobStreetAddress1 = document.getElementById('job-street-address-1');
                  console.log('street address is ', jobStreetAddress1);
                  if (jobStreetAddress1) {
                    jobStreetAddress1.innerHTML = data.address1;
                  }
                }
              },
              function(error) {
                // console.error("Error Code = " + error.code + " - " + error.message); // DEBUG
                // Error codes
                // 1 - Denied permissions
                console.log('the error getting geo: ', error);
                if (error.code === 1) {
                  trackEvent(getSecret() as string, {
                    key: 'geo-denied-by-permissions',
                    value: JSON.stringify({
                      user: cookies.user,
                      error_code: error.code,
                      app_version: window.appVersion,
                    }),
                  });
                  setStep(Steps.locationRequiredStep);
                } else {
                  // Other permissions error could have to do with something non configuration issues
                  trackEvent(getSecret() as string, {
                    key: 'geo-unknown-error',
                    value: JSON.stringify({
                      user: cookies.user,
                      error_code: error.code,
                      app_version: window.appVersion,
                    }),
                  });
                  setStep(Steps.unableToLocateStep);
                }
              }
            );
          } else {
            console.log('GEO Not Available. Skip Check');
            trackEvent(getSecret() as string, {
              key: 'geo-unavailable',
              value: JSON.stringify({
                user: cookies.user,
                app_version: window.appVersion,
              }),
            });
            setStep(Steps.damagePhotoInstructionsStep);
          }
        },
        error => {
          insp([
            'tagSession',
            {
              id: getSecret(),
              error: error,
            },
          ]);

          console.log('Job details error:', error);

          // Dev override to force behavior even when the backend is not available.
          if (window.HONK_ENV.forceLocationResults) {
            console.log('DEV: Overriding the failed job lookup for testing purposes');
            if (window.HONK_ENV.forceLocationResults === 'onlocation') {
              setStep(Steps.damagePhotoInstructionsStep);
              //setStep(Steps.cameraStep);
              //setStep(Steps.videoInstructionsStep);
            } else if (window.HONK_ENV.forceLocationResults === 'offlocation') {
              setStep(Steps.notAtJobLocationStep);
            } else if (window.HONK_ENV.forceLocationResults === 'nogeo') {
              setStep(Steps.locationRequiredStep);
            } else {
              console.log('DEV: unknown override: ' + window.HONK_ENV.forceLocationResults);
            }
          } else {
            trackEvent(getSecret() as string, {
              key: 'failed-job-lookup',
              value: JSON.stringify({
                user: cookies.user,
                app_version: window.appVersion,
              }),
            });
            setStep(Steps.damagePhotoInstructionsStep);
          }
        }
      );
  };

  useEffect(() => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then(function(result) {
        if (result.state === 'granted') {
          trackEvent(getSecret() as string, {
            key: 'geo-already-granted',
            value: JSON.stringify({
              user: cookies.user,
              app_version: window.appVersion,
            }),
          });

          console.log('geo state is granted');
          getJobDetailsAndLocation();
        } else if (result.state === 'prompt') {
          trackEvent(getSecret() as string, {
            key: 'geo-intro-step',
            value: JSON.stringify({
              user: cookies.user,
              app_version: window.appVersion,
            }),
          });

          console.log('geo state is prompt');
          setStep(Steps.geoIntroStep);
        } else if (result.state === 'denied') {
          trackEvent(getSecret() as string, {
            key: 'geo-denied-by-permissions',
            value: JSON.stringify({
              user: cookies.user,
              app_version: window.appVersion,
            }),
          });

          console.log('geo state is denied');
          setStep(Steps.locationRequiredStep);
        }
      });
    } else {
      trackEvent(getSecret() as string, {
        key: 'geo-cannot-tell-if-it-was-granted',
        value: JSON.stringify({
          user: cookies.user,
          app_version: window.appVersion,
        }),
      });

      console.log('cannot tell what is going on with geo');
      setStep(Steps.geoIntroStep);
    }
  }, []);

  useEffect(() => {
    var query = new URLSearchParams(window.location.search);
    console.log('goto is ', query.get('goto'));
    if (query.get('goto') === 'camera') {
      console.log('we should skip to the camera');
      setStep(Steps.cameraStep);
    }
  }, []);
  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  //const height = useHeight();
  //<Container style={{ height }}>

  return (
    <>
      {step === Steps.geoIntroStep && (
        <GeoIntro>
          <div className="intro-container">
            <MarkerContainer>
              <MapMarker />
            </MarkerContainer>
            <Typography variant="h1">Your location is required to use this app</Typography>
            <ul>
              <li>
                <strong>Step 1: </strong>Press "Ask for my location". A box should appear.
              </li>
              <li>
                <strong>Step 2: </strong>Press "Allow" to share your location and continue with this app.
              </li>
            </ul>
            <ButtonContainer>
              <Button onClick={proceedWithGeo}>Ask for my location</Button>
            </ButtonContainer>
          </div>
        </GeoIntro>
      )}

      {step === Steps.waitingForGeoStep && (
        <WaitingForGeo>
          <div>
            <MarkerContainer className="bounce-1">
              <MapMarker />
            </MarkerContainer>
            <Typography variant="h1">Please wait while we find your location</Typography>
          </div>
        </WaitingForGeo>
      )}

      {step === Steps.locationRequiredStep && (
        <LocationNotice>
          <NoticeHeader className="critical">
            <MapMarkerError />
            <Typography variant="h1">On Scene Photos</Typography>
            <Typography variant="h2">
              Location
              <br />
              unknown
            </Typography>
          </NoticeHeader>
          <NoticeBody>
            <>
              <Typography variant="body1">{locationUnknownInstructions}</Typography>
              <ButtonContainer>
                <Button onClick={userIndicatedOnScene} variant="contained" type="submit" color="primary">
                  {confirmOnSceneButtonLabel}
                </Button>
              </ButtonContainer>
              <ButtonContainer>
                <Button
                  onClick={userIndicatedOffScene}
                  variant="contained"
                  type="submit"
                  //color="primary"
                  className="secondary"
                >
                  {offScenePhotoButtonLabel}
                </Button>
              </ButtonContainer>
              <Typography variant="h3">Not the operator on-scene?</Typography>
              <Typography variant="body1">{notOperatorInstructions}</Typography>
              <ButtonContainer>
                <a href={textMessageToOperator}>
                  <Button onClick={sendToAnother} variant="contained" color="primary">
                    Text link to operator
                  </Button>
                </a>
              </ButtonContainer>
            </>
          </NoticeBody>
        </LocationNotice>
      )}

      {step === Steps.notAtJobLocationStep && (
        <LocationNotice>
          <NoticeHeader className="important">
            <MapMarkerNotThere />
            <Typography variant="h1">On Scene Photos</Typography>
            <Typography variant="h2">
              Not at
              <br />
              job location
            </Typography>
          </NoticeHeader>
          <NoticeBody>
            <Typography variant="body1">
              Uh oh, you are currently not located at <span className="address-item" id="job-street-address-1"></span>.{' '}
              {photoInstructions}
            </Typography>
            <ButtonContainer>
              <Button onClick={reloadApp} variant="contained" type="submit" className="do-this">
                Check my location again
              </Button>
            </ButtonContainer>
            <Typography variant="h3">Not the operator on-scene?</Typography>
            <Typography variant="body1">{notOperatorInstructions}</Typography>
            <ButtonContainer>
              <a href={textMessageToOperator}>
                <Button onClick={sendToAnother} variant="contained" color="primary">
                  Text link to operator
                </Button>
              </a>
            </ButtonContainer>

            <>
              <Typography variant="h3" className="critical-text">
                Already loaded &amp; left the disablement location?
              </Typography>
              <Typography variant="body1">{requirementInstructions}</Typography>
            </>

            <ButtonContainer>
              <Button
                onClick={toRecordOffSceneVideo}
                variant="contained"
                type="submit"
                //color="primary"
                className="secondary"
              >
                {offScenePhotoButtonLabel}
              </Button>
            </ButtonContainer>
          </NoticeBody>
        </LocationNotice>
      )}

      {step === Steps.unableToLocateStep && (
        <LocationNotice>
          <NoticeHeader className="important unknown">
            <MapMarkerUnknown />
            <Typography variant="h1">On Scene Photos</Typography>
            <Typography variant="h2">
              Unable to
              <br />
              find you
            </Typography>
          </NoticeHeader>
          <NoticeBody>
            <Typography variant="body1">
              Your current location is required to verify the photos & video were taken at the job location. This app
              encountered an error getting that information. Please retry sharing your location.
            </Typography>
            <ButtonContainer>
              <Button onClick={reloadApp} variant="contained" type="submit" color="primary">
                Retry sharing location
              </Button>
            </ButtonContainer>
            <Typography variant="h3">Not the operator on-scene?</Typography>
            <Typography variant="body1">{notOperatorInstructions}</Typography>
            <ButtonContainer>
              <a href={textMessageToOperator}>
                <Button onClick={sendToAnother} variant="contained" color="primary">
                  Text link to operator
                </Button>
              </a>
            </ButtonContainer>
          </NoticeBody>
        </LocationNotice>
      )}

      {step === Steps.damagePhotoInstructionsStep && <DamageAreaInstructionsScreen step={step} setStep={setStep} />}

      {step === Steps.cameraStep && <Camera step={step} setStep={setStep} />}

      {step === Steps.destinationChangeStep && <DestinationChangeScreen step={step} setStep={setStep} />}

      {(step === Steps.videoInstructionsStep ||
        step === Steps.videoCameraStep ||
        step === Steps.finishedRecordingStep) && (
        <Container>
          {roomState === 'disconnected' ? (
            <NotRecordingScreens step={step} setStep={setStep} />
          ) : (
            <VideoCamera setStep={setStep}>
              <ReconnectingNotification />
              <Room />
            </VideoCamera>
          )}
        </Container>
      )}
    </>
  );
}
