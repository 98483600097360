import React, { SVGProps } from 'react';
//style="enable-background:new 0 0 48 48;

export default function IconProcessing(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="84.11" height="101.755" viewBox="0 0 84.11 101.755" {...props}>
      <defs>
        <clipPath id="a-threesixtywithdistance">
          <rect width="22.366" height="45.698" fill="#15b9e0" />
        </clipPath>
        <clipPath id="b-threesixtywithdistance">
          <path
            d="M42.055,0C65.281,0,84.11,19.128,84.11,42.723S65.281,85.445,42.055,85.445c-.718,0,0-42.723,0-42.723s-6.285,31.333-7.85,30.956c-.617-.148-3.48,10.229-4.362,9.971C12.888,78.707,0,62.244,0,42.723,0,19.128,18.829,0,42.055,0Z"
            fill="#fff"
            opacity="0.999"
          />
        </clipPath>
      </defs>
      <g transform="translate(780.453 -75.988)">
        <g transform="translate(-749.581 94.669)">
          <g transform="translate(22.366 45.698) rotate(180)">
            <rect width="16.845" height="36.434" rx="8.422" transform="translate(2.973 1.982)" fill="#26242d" />
            <g transform="translate(0 0)">
              <g transform="translate(0 0)" clipPath="url(#a-threesixtywithdistance)">
                <path
                  d="M20.612,12.83S22.765,0,11.189,0C-.041,0,1.76,12.83,1.76,12.83s-2.2.4-1.682,1.958c0,0,.444.432,2.285-.555L2.237,25.345a13.137,13.137,0,0,0-.527,4.92c0,13.993,18.941,13.993,18.941,0a13.177,13.177,0,0,0-.527-4.92L20,14.233c1.848.987,2.285.555,2.285.555.533-1.556-1.671-1.958-1.671-1.958M11.245,37A9.408,9.408,0,0,1,4.9,33.219V28.7s2.483,4.646,6.443,4.646,6.184-4.779,6.184-4.779l.091,4.656A9.491,9.491,0,0,1,11.245,37m6.192-21.775a9.6,9.6,0,0,0-6.059-1.353A9.918,9.918,0,0,0,5.1,15.395L3.323,11.253a9.9,9.9,0,0,1,7.942-3.142,9.827,9.827,0,0,1,7.936,3.2Z"
                  transform="translate(0 0)"
                  fill="#15b9e0"
                />
              </g>
            </g>
          </g>
          <path
            d="M1.32.347c.446-.212,2.058-1.006.364.88a8.824,8.824,0,0,1-1.206,1.1C-.815,3.384.875.559,1.32.347Z"
            transform="translate(16.746 40.741)"
            fill="#fff"
          />
          <path
            d="M1.122.347C.676.135-.936-.659.757,1.227a8.824,8.824,0,0,0,1.206,1.1C3.257,3.384,1.567.559,1.122.347Z"
            transform="translate(3.165 40.741)"
            fill="#fff"
          />
        </g>
        <g transform="translate(-739.89 162.743)">
          <text
            transform="translate(12 12)"
            fill="#fff"
            fontSize="11"
            fontFamily="Fieldwork-Geo-Demibold, Fieldwork"
            fontWeight="300"
          >
            <tspan x="-19.911" y="0">
              360
            </tspan>
          </text>
          <g transform="translate(13.58 1.235)" fill="none" stroke="#fff" strokeWidth="1">
            <circle cx="2.469" cy="2.469" r="2.469" stroke="none" />
            <circle cx="2.469" cy="2.469" r="1.969" fill="none" />
          </g>
        </g>
        <g transform="translate(0.547 -1.012)">
          <g transform="translate(-781 77)" clipPath="url(#b-threesixtywithdistance)">
            <g transform="translate(3.202 4.803)" fill="none" stroke="#fff" strokeWidth="2" strokeDasharray="4 4">
              <ellipse cx="38.823" cy="39.223" rx="38.823" ry="39.223" stroke="none" />
              <ellipse cx="38.823" cy="39.223" rx="37.823" ry="38.223" fill="none" />
            </g>
          </g>
          <circle cx="2.466" cy="2.466" r="2.466" transform="translate(-740.537 156.418)" fill="#fff" />
          <g transform="translate(-755.633 160.977) rotate(-114)">
            <rect
              width="1.973"
              height="6.906"
              transform="matrix(0.998, 0.07, -0.07, 0.998, 0.677, -0.039)"
              fill="#fff"
            />
            <rect
              width="1.973"
              height="6.906"
              transform="matrix(-0.07, 0.998, -0.998, -0.07, 7.222, 5.363)"
              fill="#fff"
            />
          </g>
        </g>
        <g transform="translate(-78 -16.579)">
          <line x2="22.878" transform="translate(-648.5 135.289)" fill="none" stroke="#fff" strokeWidth="1" />
          <line y2="5.579" transform="translate(-648.5 132.5)" fill="none" stroke="#fff" strokeWidth="1" />
          <line y2="5.579" transform="translate(-625.622 132.5)" fill="none" stroke="#fff" strokeWidth="1" />
        </g>
        <text
          transform="translate(-709.89 115.711)"
          fill="#fff"
          fontSize="11"
          fontFamily="Fieldwork-Geo-Demibold, Fieldwork"
          fontWeight="300"
        >
          <tspan x="-9.389" y="0">
            9’
          </tspan>
        </text>
      </g>
    </svg>
  );
}
