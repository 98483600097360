import React from 'react';

export default function AvatarIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="116.7" height="147.915" viewBox="0 0 116.7 147.915">
      <g transform="translate(-542 161)">
        <path
          d="M58.35,0A58.35,58.35,0,0,1,116.7,58.35c0,32.226-58.35,89.565-58.35,89.565S0,90.576,0,58.35A58.35,58.35,0,0,1,58.35,0Z"
          transform="translate(542 -161)"
          fill="#fff"
        />
        <ellipse cx="20" cy="19.5" rx="20" ry="19.5" transform="translate(579.697 -120.868)" fill="#15b9e0" />
      </g>
    </svg>
  );
}
