import React from 'react';

export default function AvatarIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="140.976" height="162.636" viewBox="0 0 140.976 162.636">
      <defs>
        <filter id="a-map-marker-with-shadow" x="0" y="0" width="140.976" height="162.636" filterUnits="userSpaceOnUse">
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="10" result="b" />
          <feFlood flood-opacity="0.161" />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="translate(-512 186)">
        <g transform="matrix(1, 0, 0, 1, 512, -186)" filter="url(#a-map-marker-with-shadow)">
          <path
            d="M40.488,0A40.488,40.488,0,0,1,80.976,40.488c0,22.361-40.488,62.148-40.488,62.148S0,62.849,0,40.488A40.488,40.488,0,0,1,40.488,0Z"
            transform="translate(30 25)"
            fill="#fff"
          />
        </g>
        <ellipse
          cx="13.878"
          cy="13.531"
          rx="13.878"
          ry="13.531"
          transform="translate(568.157 -133.153)"
          fill="#15b9e0"
        />
      </g>
    </svg>
  );
}
