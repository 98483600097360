import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
//import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useLocation, BrowserRouter as Router } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
//import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { urlencoded } from 'express';
import { CookiesProvider } from 'react-cookie';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const query = useQuery();

  useEffect(() => {
    // Hit the funnel tracking URL so we can see when the app is viewed and from what source
    const formData = new FormData();
    formData.append('secret', query.get('id') || '');
    if (query.get('fromapp')) {
      console.log('opened from partner app source');
      formData.append('event', 'open_from_app');
    } else if (query.get('handoff')) {
      console.log('opened from handoff source');
      formData.append('event', 'open_from_handoff');
    } else if (query.get('fromemail')) {
      console.log('opened from email source');
      formData.append('event', 'open_from_email');
    } else {
      console.log('opened from text source');
      formData.append('event', 'open_from_txt');
    }

    fetch(window.HONK_ENV.baseUrl + 'accident_videos/stamp', {
      method: 'POST',
      mode: 'cors',
      body: formData,
    }).then(
      result => {
        console.log('stamp result:', result);
      },
      error => {
        console.log('stamp error:', error);
      }
    );
  }, []);

  return (
    <CookiesProvider>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <App />
      </VideoProvider>
    </CookiesProvider>
  );

  /* TODO REMOVE keeping for reference for now
  return (
    <UnsupportedBrowserWarning>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <App />
      </VideoProvider>
    </UnsupportedBrowserWarning>
  );
  */
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <AppStateProvider>
        <VideoApp />
      </AppStateProvider>
    </Router>
  </MuiThemeProvider>,
  document.getElementById('root')
);

/* XXX Original
ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <AppStateProvider>
        <Switch>
          <PrivateRoute exact path="/">
            <VideoApp />
          </PrivateRoute>
          <PrivateRoute path="/room/:URLRoomName">
            <VideoApp />
          </PrivateRoute>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Redirect to="/" />
        </Switch>
      </AppStateProvider>
    </Router>
  </MuiThemeProvider>,
  document.getElementById('root')
);
*/
