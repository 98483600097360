import React from 'react';
//import clsx from 'clsx';
//import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Steps } from '../../../App';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) => ({
  stopRecording: {
    display: 'block',
    width: '70px',
    height: '70px',
    borderRadius: '80px',
    border: 'solid 4px #ffffff',
    margin: '0 auto',
    boxShadow: '0 0 40px rgba(0,0,0,0.3)',
  },
  stopIcon: {
    width: '30px',
    height: '30px',
    borderRadius: '6px',
    margin: '2px auto',
    background: '#bb1144',
  },
}));

interface EndCallButtonProps {
  setStep: (step: Steps) => void;
}

export default function EndCallButton({ setStep }: EndCallButtonProps) {
  const classes = useStyles();
  const { room } = useVideoContext();

  const handleClick = () => {
    setStep(Steps.finishedRecordingStep);
    room.disconnect();
    window.appState.recordingVideo = false;
  };

  return (
    <Button onClick={handleClick} className={classes.stopRecording} data-cy-disconnect>
      <div className={classes.stopIcon}></div>
    </Button>
  );
}
