import React from 'react';
import { makeStyles, Button, Theme } from '@material-ui/core';
//import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import { useAppState } from '../../../state';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { JOB_DOCUMENT_CATEGORY } from '../../../constants';
import { useLocation } from 'react-router-dom';
import trackEvent from '../../../utils/trackEvent/trackEvent';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles((theme: Theme) => ({
  startRecording: {
    display: 'block',
    width: '70px',
    height: '70px',
    borderRadius: '80px',
    border: 'solid 4px #ffffff',
    margin: '0 auto',
    background: '#bb1144',
    boxShadow: '0 0 40px rgba(0,0,0,0.3)',
    '&:hover': {
      background: '#cc1144',
    },
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function StartCallButton() {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { connect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const [cookies, setCookie] = useCookies(['user']);
  let query = useQuery();
  const name = Math.random()
    .toString(36)
    .slice(2);
  //<job-document-category>-<job-call-id>-<whatever random junk></whatever>
  const roomName =
    JOB_DOCUMENT_CATEGORY +
    ':' +
    query.get('id') +
    ':' +
    Math.random()
      .toString(36)
      .slice(2);

  const handleJoin = () => {
    window.appState.recordingVideo = true;

    var thinkingOverlay = document.getElementById('thinking');
    if (thinkingOverlay) {
      thinkingOverlay.style.display = 'block';
    }

    // Sometimes we have no GEO information
    console.log('driver scene data is ', window.driver);
    if (window.driver.onScene) {
      trackEvent(query.get('id') as string, {
        key: 'location',
        value: JSON.stringify({
          user: cookies.user,
          on_scene: window.driver.onScene,
          lat: window.driver.lat,
          long: window.driver.long,
          distance_to_disablement: window.driver.distanceFromJob,
          app_version: window.appVersion,
        }),
      });
    } else {
      console.log('no driver on scene data');
    }

    getToken(name, roomName).then(token => connect(token));
  };

  return (
    <Button className={classes.startRecording} data-cy-join-now onClick={handleJoin} disabled={disableButtons}>
      &nbsp;
    </Button>
  );
}
