export default function getSecret() {
  // get the secret from the path or an "id" query parameter
  // secret used to be in a query string parameter but query string parameters do not make it
  // to the messages app due to tracking / security features of iOS
  var id = '';
  var path = window.location.pathname;
  if (path && path.length > 0) {
    id = path.substr(1);
  }

  if (id.length > 0) {
    return id;
  }

  return new URLSearchParams(window.location.search).get('id');
}
