export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  //width: 1920,
  //height: 1080,
  width: 1280,
  height: 720,
  frameRate: 30,
  facingMode: 'environment',
};

/*
export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};
*/

// HONK specific
export const JOB_DOCUMENT_CATEGORY = 'vehicle_360_video';

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';
