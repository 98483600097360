import React, { useState, useEffect } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import PreflightTest from './PreflightTest/PreflightTest';
import VideoInstructionScreen from './VideoInstructionScreen/VideoInstructionScreen';
import VideoRecordedScreen from './VideoRecordedScreen/VideoRecordedScreen';
//import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Steps } from '../../App';
import Video from 'twilio-video';
import { useLocation } from 'react-router-dom';
import { styled } from '@material-ui/core';
import IconProcessing from '../Camera/IconProcessing';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface NotRecordingScreensProps {
  step: number;
  setStep: (step: Steps) => void;
}

const ThinkingContainer = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: 'rgba(0,0,0,0.6)',
}));

const Thinking = styled('div')(() => ({
  width: '80px',
  height: '80px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translateY(-50%) translateX(-50%)',
}));

const BlankScreen = styled('div')(() => ({
  width: '100%',
  height: '100%',
  background: '#000000',
}));

export default function NotRecordingScreens({ step, setStep }: NotRecordingScreensProps) {
  //const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  let query = useQuery();
  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (step === Steps.videoCameraStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }

    if (step === Steps.finishedRecordingStep) {
      window.location.href =
        window.HONK_ENV.HONKRequestURL +
        '/' +
        query.get('id') +
        '/accident_tow_photos' +
        (query.get('fromapp') ? '/?fromapp=' + query.get('fromapp') : '');
    }
  }, [getAudioAndVideoTracks, setStep, step, mediaError, query]);

  const SubContent = (
    <>
      {Video.testPreflight && <PreflightTest />}
      <MediaErrorSnackbar error={mediaError} />
    </>
  );

  return (
    <>
      {step === Steps.videoInstructionsStep && (
        <IntroContainer>
          <VideoInstructionScreen step={step} setStep={setStep} />
        </IntroContainer>
      )}

      {step === Steps.videoCameraStep && (
        <IntroContainer dark subContent={step === Steps.videoCameraStep && SubContent}>
          <DeviceSelectionScreen setStep={setStep} />
        </IntroContainer>
      )}

      {step === Steps.finishedRecordingStep && (
        <BlankScreen>
          <ThinkingContainer id="thinking">
            <Thinking>
              <IconProcessing className="rotating" />
            </Thinking>
          </ThinkingContainer>
        </BlankScreen>
      )}
    </>
  );
}
