import React, { SVGProps } from 'react';

export default function IconRotatePhone(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="128.181" height="130.413" viewBox="0 0 128.181 130.413" {...props}>
      <defs>
        <clipPath id="a-obstructedvideodiagram">
          <rect width="25.55" height="52.205" fill="#15b9e0" />
        </clipPath>
        <clipPath id="b-obstructedvideodiagram">
          <path
            d="M48.043,0C74.576,0,96.086,21.851,96.086,48.805S74.576,97.611,48.043,97.611c-.82,0,0-48.805,0-48.805S12.767,28.225,8.563,20.987A47.666,47.666,0,0,1,48.043,0Z"
            fill="#26242d"
            opacity="0.999"
          />
        </clipPath>
      </defs>
      <g transform="translate(-126.873 -614.487)">
        <g transform="translate(919.911 552.383)">
          <g transform="translate(-745.186 97.328)">
            <g transform="translate(25.55 52.205) rotate(180)">
              <rect width="19.243" height="41.622" rx="9" transform="translate(3.396 2.264)" fill="#26242d" />
              <g transform="translate(0 0)">
                <g transform="translate(0 0)" clipPath="url(#a-obstructedvideodiagram)">
                  <path
                    d="M23.547,14.656S26.007,0,12.782,0C-.047,0,2.011,14.656,2.011,14.656S-.5,15.116.089,16.893c0,0,.507.494,2.61-.634L2.555,28.954a15.007,15.007,0,0,0-.6,5.62c0,15.985,21.638,15.985,21.638,0a15.054,15.054,0,0,0-.6-5.62l-.144-12.695c2.111,1.127,2.61.634,2.61.634.609-1.777-1.908-2.237-1.908-2.237m-10.7,27.608A10.748,10.748,0,0,1,5.6,37.948V32.781s2.837,5.308,7.36,5.308,7.064-5.459,7.064-5.459l.1,5.319a10.843,10.843,0,0,1-7.285,4.316m7.073-24.876A10.964,10.964,0,0,0,13,15.844a11.33,11.33,0,0,0-7.176,1.743L3.8,12.855a11.3,11.3,0,0,1,9.072-3.589,11.226,11.226,0,0,1,9.066,3.654Z"
                    transform="translate(-0.001 0)"
                    fill="#15b9e0"
                  />
                </g>
              </g>
            </g>
            <path
              d="M1.508.4C2.018.154,3.859-.752,1.925,1.4A10.081,10.081,0,0,1,.546,2.653C-.931,3.866,1,.639,1.508.4Z"
              transform="translate(19.13 46.541)"
              fill="#fff"
            />
            <path
              d="M1.281.4C.772.154-1.069-.752.865,1.4A10.08,10.08,0,0,0,2.243,2.653C3.72,3.866,1.79.639,1.281.4Z"
              transform="translate(3.616 46.541)"
              fill="#fff"
            />
          </g>
          <g transform="translate(-780.453 75.988)">
            <g
              transform="matrix(0.809, 0.588, -0.588, 0.809, 37.862, -18.918)"
              clipPath="url(#b-obstructedvideodiagram)"
            >
              <g transform="translate(3.658 5.487)" fill="none" stroke="#26242d" strokeWidth="2" strokeDasharray="4 4">
                <ellipse cx="44.351" cy="44.808" rx="44.351" ry="44.808" stroke="none" />
                <ellipse cx="44.351" cy="44.808" rx="43.351" ry="43.808" fill="none" />
              </g>
            </g>
            <circle
              cx="2.818"
              cy="2.818"
              r="2.818"
              transform="matrix(0.809, 0.588, -0.588, 0.809, 21.931, 81.651)"
              fill="#26242d"
            />
            <g transform="translate(36.04 1.629) rotate(23)">
              <rect
                width="2.254"
                height="7.889"
                transform="matrix(0.998, 0.07, -0.07, 0.998, 0.55, 0)"
                fill="#26242d"
              />
              <rect
                width="2.254"
                height="7.889"
                transform="matrix(-0.07, 0.998, -0.998, -0.07, 8.027, 6.172)"
                fill="#26242d"
              />
            </g>
          </g>
        </g>
        <g transform="translate(1 241)">
          <g transform="translate(136 393)" fill="none" stroke="#ffca40" strokeWidth="2">
            <rect width="33" height="74" stroke="none" />
            <rect x="1" y="1" width="31" height="72" fill="none" />
          </g>
          <g transform="translate(-387.729 377.674) rotate(-45)">
            <rect width="4" height="20" transform="translate(343 409)" fill="#ffca40" />
            <rect width="4" height="20" transform="translate(335 421) rotate(-90)" fill="#ffca40" />
          </g>
        </g>
      </g>
    </svg>
  );
}
