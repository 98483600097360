import React, { SVGProps } from 'react';
//style="enable-background:new 0 0 48 48;

export default function IconProcessing(props: SVGProps<SVGSVGElement>) {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48 48" fill="#ffffff" {...props}>
      <path d="M24,42.7c10.75,0,19.5-8.75,19.5-19.5c0-0.83-0.67-1.5-1.5-1.5s-1.5,0.67-1.5,1.5c0,9.1-7.4,16.5-16.5,16.5  c-9.1,0-16.5-7.4-16.5-16.5c0-6.46,3.8-12.36,9.69-15.03c0.75-0.34,1.09-1.23,0.75-1.99c-0.34-0.75-1.23-1.09-1.99-0.75  C8.99,8.59,4.5,15.56,4.5,23.2C4.5,33.95,13.25,42.7,24,42.7z"></path>
    </svg>
  );
}
