export default function trackEvent(id: string, keyValues: any) {
  const formData = new FormData();
  formData.append('secret', id);
  for (const prop in keyValues) {
    //console.log(`key is ${prop} value is ${keyValues[prop]}`);
    formData.append(prop, keyValues[prop]);
  }

  fetch(window.HONK_ENV.baseUrl + 'accident_videos/event', {
    method: 'POST',
    mode: 'cors',
    body: formData,
  }).then(
    result => {
      console.log('trackEvent result:', result);
    },
    error => {
      console.log('trackEvent error:', error);
    }
  );
}
