import React from 'react';
import { Typography, makeStyles, Grid, Hidden, Box, Button, Theme, styled } from '@material-ui/core';
import { Steps } from '../../../App';
import MapMarkerWithShadow from '../../../icons/MapMarkerWithShadow';

const useStyles = makeStyles((theme: Theme) => ({
  getStartedTitle: {
    marginBottom: '.3em',
  },
  explanation: {
    marginBottom: '20px',
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  instructionContent: {
    boxSizing: 'border-box',
    maxWidth: '480px',
    margin: '0 auto',
    padding: '20px',
  },
}));

const DestinationChangeContent = styled('div')({
  height: '100%',
  background: '#000000',
  display: 'flex',
  'flex-direction': 'column',
  '& h1': {
    fontSize: '30px',
    marginTop: 0,
    padding: '20px 35px 0',
    color: '#ffffff',
  },
  '& h2': {
    fontSize: '20px',
    color: '#15b9e0',
  },
  '& .destination-change-notice': {
    '& p': {
      color: '#ffffff',
    },
  },
  '& .destination-change-newloc': {
    background: '#ffffff',
    'flex-grow': '1',
    '& .map-marker-container': {
      position: 'absolute',
      width: '120px',
      marginLeft: '-110px',
      marginTop: '-100px',
      '& svg': {
        width: '100%',
        height: 'auto',
      },
    },
    '& .new-address': {
      marginTop: '10px',
    },
  },
  '& .actions': {
    background: '#ffffff',
    padding: '10px 35px 20px',
    textAlign: 'center',
    '& button, & .button': {
      maxWidth: '220px',
    },
  },
});

interface DestinationChangeScreenProps {
  step: number;
  setStep: (step: Steps) => void;
}

export default function DestinationChangeScreen({ setStep }: DestinationChangeScreenProps) {
  const classes = useStyles();

  const handleUnderstoodClick = () => {
    setStep(Steps.videoInstructionsStep);
  };

  return (
    <DestinationChangeContent>
      <Typography variant="h1">Tow destination change</Typography>
      <Box
        p={{ xs: '20px 35px 15px 60px', sm: '10px 45px 15px 120px', md: '15px 55px 15px 130px' }}
        className="destination-change-notice"
      >
        <Typography variant="body1">
          Based on the photo taken, the vehicle destination has changed. Please drop off the vehicle at the new
          destination below.
        </Typography>
      </Box>
      <Box
        p={{ xs: '10px 35px 10px 60px', sm: '10px 45px 10px 120px', md: '10px 55px 10px 130px' }}
        className="destination-change-newloc"
      >
        <Box className="map-marker-container">
          <MapMarkerWithShadow />
        </Box>
        <Box className="new-address-container">
          <Typography variant="h2">New drop-off location</Typography>
          <Typography variant="body1" className="new-address">
            {window.job.destinationAddress}
          </Typography>
        </Box>
      </Box>
      <Box className="actions">
        <Button onClick={handleUnderstoodClick} variant="contained" color="primary" className={classes.button}>
          Understood
        </Button>
      </Box>
    </DestinationChangeContent>
  );
}
